import React from "react";
import { Card, Image, Rating, Modal, Button, Label, Form } from 'semantic-ui-react';
import PropertyBeds from '../../components/PropertyBeds';
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css"
import mixpanel from 'mixpanel-browser';


const PropertyCard = ({ data, setUrl, handleRedirect }) => {
    const { prices, baths, photos, name, addresses, totalUnits, beds, sizes } = data;
    const [open, setOpen] = React.useState(false)
    const [Adults, setAdults] = React.useState('')
    const [Child, setChild] = React.useState('')
    const [Rooms, setRooms] = React.useState('')
    const [From, setFrom] = React.useState('')
    const [To, setTo] = React.useState('')
    const address =  !data.isBookingDotCom&&addresses[0]
    const url = !data.isBookingDotCom&&JSON.parse(JSON.parse(photos))
    // const prize = !data.isBookingDotCom&&priceText([totalPrices.gte, totalPrices.lt])


    const ActionTrigger = (Data) => {
        setUrl(Data)
        setOpen(true)
    }
    

    const setDataCapture = () => {
      mixpanel.track('Booking.com Data', {
        'ndbId': "0",
        'adults': Adults,
        'children': Child,
        'rooms': Rooms,
        'from': From,
        'to': To
      });
      setOpen(false)
    }

    return (
        <Card fluid className="property-card-container">
        {
            data.isBookingDotCom ?
            <>
            <Card.Content data-toggle="modal" data-target="#CredentialsModal" onClick={() => ActionTrigger(data.hotelUrl)}>
            <div className="image-wrapper">
                <Image
                    floated='left'
                    size='small'
                    src={'assets/img/good-hotel-london.jpg'}
                />
                </div>
                <div className="content-wrapper">
                <Card.Header>{data.name}{/* <span>Date Listed: {moment(updatedAt).format('D MMM YYYY')}</span> */}</Card.Header>
                <Card.Meta className="meta-address">{data.address}</Card.Meta>
                <Card.Meta className="meta-details">
                  <Label color='teal' >{data.review}</Label><span className="seperator"></span><Rating icon='star' disabled defaultRating={data.stars} maxRating={5} />
                </Card.Meta>
                <Card.Meta className="price-txt">
                    ${data.totalPrices}/Night <strong>|</strong> {data.totalUnits} rooms
                </Card.Meta>
                <Card.Meta className="rental-service-col">
                  <div className="service-label">Short term rental</div>
                  <div className="gift-point-wrap">
                    <div className="gift-point-icon"><img src="../../assets/img/gift-icon.svg" alt="gift points" /></div>
                    <div className="gift-popup-wrap">
                      <img src="../../assets/img/gift-colored-icon.svg" alt="gift points" />
                      <span>Get 500 Loyalty Points</span>
                    </div>
                  </div>
                </Card.Meta>
                </div>
                {/* <Card.Content textAlign="right">
                    <Icon name="arrow right" className="right-arrow-icon" bordered inverted size='large' color='purple' onClick={() => handleRedirect(data)} />
                </Card.Content> */}
            </Card.Content>
            <Modal
      className="semanticModal booking-modal"
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
    >
      {/* <Modal.Header>Gift Loyalty Points</Modal.Header> */}
      <Modal.Content>
        <Modal.Description>
          <div className="modal-title">Booking Information</div>
          <div className="modal-subtitle">Please fill out the below information to check the property detail</div>
          <div className="note-text">Note: You will be redirected to booking.com to check the property information</div>
          <Form>
            <Form.Group widths='equal'>
              <Form.Field>
                <label>Check In Date</label>
                <Datetime onChange={(e) => setFrom(e._d)} />
              </Form.Field>
              <Form.Field>
                <label>Check Out Date</label>
                <Datetime onChange={(e) => setTo(e._d)} />
              </Form.Field>
            </Form.Group>
            <Form.Input
              type="number"
              name='name'
              label="Number of Adults"
              onChange={({ target: { value } }) => {
                setAdults(value);
              }}
            />
            <Form.Input
              type="number"
              name='name'
              label="Number of Children"
              onChange={({ target: { value } }) => {
                setChild(value);
              }}
            />
            <Form.Input
              type="number"
              name='name'
              label="Number of Rooms"
              onChange={({ target: { value } }) => {
                setRooms(value);
              }}
            />
            <Button fluid content='To Booking' className='btn-color mt-4' data-toggle="modal" data-target="#InstructionsModal"
            disabled={
              !Child ||!Adults||!From||!To||!Rooms
            }
             onClick={() => setDataCapture()}></Button>
          </Form>


          <Form>
            {/* <Form.Group >
              <Form.Field widths="equal">
                <Datetime onChange={(e) => setFrom(e._d)} />
              </Form.Field>
              <Form.Field widths="equal">
                <Datetime onChange={(e) => setTo(e._d)} />
                </Form.Field>
            </Form.Group> */}
            {/* <Form.Input
              placeholder='Number of Adults'
              type="number"
              name='name'
              onChange={({ target: { value } }) => {
                setAdults(value);
              }}
            /> */}
            {/* <Form.Input
              placeholder='Number of Children'
              type="number"
              name='name'
              onChange={({ target: { value } }) => {
                setChild(value);
              }}
            /> */}
            {/* <Form.Input
              placeholder='Number of Rooms'
              type="number"
              name='name'
              onChange={({ target: { value } }) => {
                setRooms(value);
              }}
            /> */}
            {/* <Button fluid content='To Booking' className='btn-color mt-4' data-toggle="modal" data-target="#InstructionsModal" onClick={() => setOpen(false)}></Button> */}
          </Form>
        </Modal.Description>
      </Modal.Content>
     
    </Modal>
            </>
            :

            <Card.Content onClick={() => handleRedirect(data)}>
                <div className="image-wrapper">
                <Image
                    floated='left'
                    size='small'
                    src={url  && url[0] ? url[0] : 'assets/img/no-image-available.jpg'}
                />
                </div>
                <div className="content-wrapper">
                <Card.Header>{name}{/* <span>Date Listed: {moment(updatedAt).format('D MMM YYYY')}</span> */}</Card.Header>
                <Card.Meta className="meta-address">{(address.streetOne + " " + address.city + " " + address.state + " " +address.zip).replaceAll('undefined', ' ')}</Card.Meta>
                <Card.Meta className="meta-details">
                    <PropertyBeds bedrooms={beds} bathrooms={baths} sqft={sizes} />
                </Card.Meta>
                <Card.Meta className="rental-service-col">
                  <div className="service-label">Long term rental</div>
                  <div className="gift-point-wrap">
                    <div className="gift-point-icon"><img src="../../assets/img/gift-icon.svg" alt="gift points" /></div>
                    <div className="gift-popup-wrap">
                      <img src="../../assets/img/gift-colored-icon.svg" alt="gift points" />
                      <span>Get 500 Loyalty Points</span>
                    </div>
                  </div>
                </Card.Meta>
                <Card.Meta className="price-txt">
                    ${prices&&prices[0]} - ${prices&&prices[1]} <strong>|</strong> {totalUnits} units
                </Card.Meta>
                </div>
                {/* <Card.Content textAlign="right">
                    <Icon name="arrow right" className="right-arrow-icon" bordered inverted size='large' color='purple' onClick={() => handleRedirect(data)} />
                </Card.Content> */}
            </Card.Content>
        }
        </Card>
    )
}
export default PropertyCard