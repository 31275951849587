import React, { useEffect, useState } from "react";
import { Segment, Grid, Image, Button, Card, List, } from 'semantic-ui-react';
import Header from "../../components/Header";
import FooterMain from "../../components/Footer/FooterMain";
import { ToastContainer } from 'react-toastify';
import PropertyMap from "../Properties/PropertyMap";
import PropertyBeds from "../../components/PropertyBeds";
import TourSection from "../../components/TourSection/TourSection";
import { useHistory, withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { compose } from 'recompose';
import { getAuthToken } from "../../store/auth";
import config from '../../config'
import './propertyDetail.scss';
const parsePhotos = (photos) => {
    if (typeof (photos) === 'string') {
        try {
            return parsePhotos(JSON.parse(photos));
        } catch (e) {
            return photos;
        }
    }
    return photos;
}


const PropertyDetail = ({ client, auth }) => {
    const history = useHistory();
    /* eslint-disable */
    const [property, setProperty] = useState(history.location.state.data);
    const [units, setUnits] = useState(history.location.state.units);
    /* eslint-enable */
    const propertyId = property && property.id
    const locationId = property && property.locationId
    const propertyPhoto = property.photos && JSON.parse(JSON.parse(property.photos))
    const address = property.addresses &&property.addresses[0]

    /* eslint-disable */
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    /* eslint-enable */


    const goToRenter = (unitId, unitNumber, bedrooms, bathrooms, sqft) => {
        if (!Boolean(getAuthToken())) {
            window.open(`${config.renterPortalUri}`)
        } else {
            window.open(`${config.renterPortalUri}/#/propertyDetail/${propertyId}/${locationId}/${unitId}/${unitNumber}/${bedrooms}/${bathrooms}/${sqft}/${property.name.split(' ').join('_')}`, '_blank')
        }
    }
    const parseContact = (val) => {
        const social= JSON.parse(JSON.parse(property.contact))
        if(social){
            if (val === 'phone'){
                if (social.phone){
                    var cleaned = ('' + social.phone).replace(/\D/g, '');
                    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
                    return  <div className="contact-info">{'(' + match[1] + ') ' + match[2] + '-' + match[3]}</div> 
                }
            }
            if (val === 'email'){
                if (social.email){
                    return  <div className="contact-info">{social.email}</div> 
                }
            }
            if (val === 'social'){
                if (social.socials){
                            return  (
                            <>
                            <div className="contact-info"> <img src="assets/img/marketing/icons-facebook.svg" alt="facebook" /><a href={social.socials.facebook} rel="noopener noreferrer" target="_blank">{social.socials.facebook}</a></div>
                            <div className="contact-info"> <img src="assets/img/marketing/icons-instagram.svg" alt="instagram" /><a href={social.socials.instagram} rel="noopener noreferrer" target="_blank">{social.socials.instagram}</a></div> 
                            <div className="contact-info"> <img src="assets/img/marketing/icons-twitter.svg" alt="twitter" /><a href={social.socials.twitter} rel="noopener noreferrer" target="_blank">{social.socials.twitter}</a></div> 
                            <div className="contact-info"> <img src="assets/img/marketing/icons8-yelp.svg" alt="yelp" /><a href={social.socials.yelp} rel="noopener noreferrer" target="_blank">{social.socials.yelp}</a></div> 
                            <div className="contact-info"> <img src="assets/img/marketing/icons-linkedin.svg" alt="linkedin" /><a href={social.socials.linkedIn} rel="noopener noreferrer" target="_blank">{social.socials.linkedIn}</a></div> 
                            </>
    
                            ) 
                }
            }

        }
    }
    const amenList = () => {
        if(property.amenities){
            const amenities = JSON.parse(JSON.parse(property.amenities))
            return amenities.map(item => {
                return(
                    <List.Item>
                    <Image avatar src='./assets/img/Star.png' />
                    <List.Content>
                      <List.Header style={{textTransform:"capitalize"}}>{item.name}</List.Header>
                    </List.Content>
                  </List.Item>
                )
            })
        }
    }
    const priceRange = () => {
        if(units){
            const closest = units.reduce(function(prev, curr) {return prev.price < curr.price ? prev : curr;});
            const furthest = units.reduce(function(prev, curr) {return prev.price > curr.price ? prev : curr;});
            if(closest.price  === furthest.price ){
                return <span>{closest.price }</span>
            }
            else return <span>${closest.price } - ${furthest.price }</span>
        }
    }
    const SqftRange = () => {
        if(units){
            const closest = units.reduce(function(prev, curr) {return prev.sqft < curr.sqft ? prev : curr;});
            const furthest = units.reduce(function(prev, curr) {return prev.sqft > curr.sqft ? prev : curr;});
            if(closest.sqft === furthest.sqft){
                return <span>{closest.sqft}</span>
            }
            else return <span>{closest.sqft} - {furthest.sqft}</span>
        }
    }
    const BedRange = () => {
        if(units){
            const closest = units.reduce(function(prev, curr) {return prev.bedrooms < curr.bedrooms ? prev : curr;});
            const furthest = units.reduce(function(prev, curr) {return prev.bedrooms > curr.bedrooms ? prev : curr;});
            if(closest.bedrooms === furthest.bedrooms){
                return <span>{closest.bedrooms}</span>
            }
            else return <span>{closest.bedrooms} - {furthest.bedrooms}</span>
        }
    }
    const BathRange = () => {
        if(units){
            const closest = units.reduce(function(prev, curr) {return prev.bathrooms < curr.bathrooms ? prev : curr;});
            const furthest = units.reduce(function(prev, curr) {return prev.bathrooms > curr.bathrooms ? prev : curr;});
            if(closest.bathrooms === furthest.bathrooms){
                return <span>{closest.bathrooms}</span>
            }
            else return <span>{closest.bathrooms} - {furthest.bathrooms}</span>
        }
    }

    return (<>
        <div className="renter-listing-header">
            <Header />
        </div>
        <Segment className="property-detail-layout">
            <Segment className="property-detail-wrapper">
                <Grid columns={2} >
                    <Grid.Row stretched>
                        <Grid.Column width={16}>
                            <Image className="property-img" src={propertyPhoto&&propertyPhoto[0] ? propertyPhoto[0] : 'assets/img/no-image-available.jpg'} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row stretched>
                        <Grid.Column width={11}>
                            {/* <Image src={propertyPhoto ? propertyPhoto[0] : 'assets/img/no-image-available.jpg'} /> */}
                            <div className="property-information">
                                <div className="property-title">{property.name}</div>
                                <div className="property-address">{(address.streetOne + " " + address.city + " " + address.state + " " +address.zip).replaceAll('undefined', ' ')}</div>
                                <div className="property-info-row">
                                    <div className="property-info-col">{BedRange()} Beds</div>
                                    <span className="dot-divider"></span>
                                    <div className="property-info-col">{BathRange()} Baths</div>
                                    <span className="dot-divider"></span>
                                    <div className="property-info-col">{SqftRange()} sq.ft</div>
                                </div>
                                <div className="property-contact-row">
                                    <div className="property-contact-col">
                                        <div className="contact-label">Phone</div>
                                        {property.contact &&parseContact('phone')}
                                    </div>
                                    <div className="property-contact-col">
                                        <div className="contact-label">Email</div>
                                        <div className="contact-info">{property.contact &&parseContact('email')}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="property-description">

                            <pre style={{fontFamily:"Be Vietnam"}}>{property.description}</pre>
                            </div>
                            <div className="property-services-row">
                                <div className="property-services-title">Amenities</div>
                                <div className="services-list">
                                <List horizontal relaxed>
                                {amenList()}
                                </List>
                                </div>
                            </div>
                            <div className="mt-1em mb-1em">
                                <Segment className="property-detail-container">
                                    <h2>Basic Information</h2>
                                    <List as='ul'>
                                        <List.Item as='li'>Property Name: {property.name}</List.Item>
                                        <List.Item as='li'>Year built: {property.yearBuilt}</List.Item>
                                        <List.Item as='li'>Date listed: {property.createdAt}</List.Item>
                                        <List.Item as='li'>Socials</List.Item>
                                        {property.contact &&parseContact('social')}
                                        {/* {propertyTypeOptions.map((e) => { return (<List.Item as='li'>{e.key}:</List.Item>) })} */}
                                    </List> 
                                </Segment>
                            </div>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Card className="property-price-card" fluid>
                                <div className="price-detail">{priceRange()} <span className="divider">|</span> {units.length} units</div>
                                <div className="price-label">Property Price</div>
                                {/* <PropertyPrice detail="PropertyPrice__detail" price={property.totalPrices} unit={property.totalUnits} /> */}
                                {/* <Card.Content textAlign="center" className="detail-cards">
                                    <h2 textAlign="center" className="mt-45">{property.name}</h2>
                                    <Card.Meta>{address.streetOne} {address.city} {address.state} {address.zip}</Card.Meta>
                                    <Card.Meta>
                                        <PropertyBeds bedrooms={property.totalBeds} bathrooms={property.totalBaths} sqft={property.totalSizes} />
                                    </Card.Meta>
                                    <div className="mt-12">
                                        {
                                            contact !== null &&
                                            <div>
                                                Phone: {contact?.phone}
                                            </div>

                                        }
                                        {
                                            contact !== null &&
                                            <div>
                                                Email:{contact?.email}
                                            </div>
                                        }
                                    </div>
                                </Card.Content> */}
                                <Card.Content extra className="button-group-container tour-btn mt-20">
                                    <div className='ui two buttons'>
                                        <TourSection units={units} propertyId={propertyId} />
                                        
                                    </div>
                                </Card.Content>
                            </Card>
                            <div className="property-details-map">
                                <div className="property-location-label">location</div>
                                <PropertyMap
                                    searchStatus={true}
                                    properties={[property]}
                                    zoom={9}
                                    isDetail={true} />
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <div className="property-units-wrapper" style={{ maxHeight: '500px', overflowY: "auto", overflowX: "hidden", marginTop: '30px' }}>
                    <Card.Group itemsPerRow={5} stackable>
                        {
                            units &&
                            units.map(unit => {
                                const { id, bedrooms, bathrooms, sqft, number, photos, price } = unit
                                // let photo = photos && (JSON.parse(photos))
                                const photo = parsePhotos(photos);
                                console.log(unit)
                                return (
                                    <Card>
                                    {
                                        photo&& photo.length === 0 &&
                                        <Image
                                            fluid
                                            style={{ background: "white" }}
                                            wrapped ui={false}
                                            src={'assets/img/no-image-available.jpg'}
                                        />
                                    }
                                    {
                                        !photo &&
                                        <Image
                                            fluid
                                            style={{ background: "white" }}
                                            wrapped ui={false}
                                            src={'assets/img/no-image-available.jpg'}
                                        />
                                    }
                                    {
                                        photo&& photo.length !== 0 &&
                                        <Image
                                            fluid
                                            style={{ background: "white" }}
                                            wrapped ui={false}
                                            src={photo&& photo[0] }
                                        />
                                    }
                                        <Card.Content>
                                            <Card.Header className="unit-card-header" textAlign="center">Unit: {number}  
                                            <span className="seperator"></span>
                                            <strong> ${price || 0}/month</strong></Card.Header>
                                            <Card.Meta className="prop-unit-info" textAlign="center">
                                                <PropertyBeds bedrooms={bedrooms} bathrooms={bathrooms} sqft={sqft} price={price} />
                                            </Card.Meta>
                                            {
                                                unit.status === "vacant"?
                                            <Button className="main-button" onClick={() => goToRenter(id, number, bedrooms, bathrooms, sqft)} >Rent Now</Button>:
                                            <Button className="main-button" disabled onClick={() => goToRenter(id, number, bedrooms, bathrooms, sqft)} >Unavailable</Button>
                                            }
                                            {/* <Card.Content extra >
                                                <div style={{ padding: "5px 5px 15px 5px" }}>
                                                    Price: ${price} /month
                                                </div>
                                            </Card.Content> */}
                                        </Card.Content>
                                    </Card>
                                )
                            })
                        }
                    </Card.Group>
                </div>
            </Segment>
            <FooterMain />
        </Segment>
        {/* <SignIn
            isOpen={isOpen}
            setOpen={handleModalToggle}
            isloading={isloading}
            setLoading={setLoading}
            isPropertyDetail={true}
        /> */}
        <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        />
    </>
    )
}

export default compose(withRouter, withApollo)(PropertyDetail)
