// @flow

const prodConfig = {
  apiKey: 'AIzaSyCPImmNznKLDej_mlD3tsBm1EqvXPtWVb8',
  authDomain: 'leasera-demo.firebaseapp.com',
  databaseURL: 'https://leasera-demo.firebaseio.com',
  projectId: 'leasera-demo',
  storageBucket: 'leasera-demo.appspot.com',
  messagingSenderId: '787660496150',
}

// const devConfig = {
//   apiKey: YOUR_API_KEY,
//   authDomain: YOUR_AUTH_DOMAIN,
//   databaseURL: YOUR_DATABASE_URL,
//   projectId: YOUR_PROJECT_ID,
//   storageBucket: '',
//   messagingSenderId: YOUR_MESSAGING_SENDER_ID,
// };

export const config = process.env.NODE_ENV === 'production' ? prodConfig : prodConfig // : devConfig;
