
import React from "react";
import Header from "../../components/Header";
import FooterMain from "../../components/Footer/FooterMain";
import Newsletter from "../../components/Newsletter";
import ExpertsTestimonial from "../../components/ExpertsTestimonial";
import { testimonialContent } from '../../utils/common';

const GroceryShopping = () => {

    const header = testimonialContent.grocery.header;
    const text = testimonialContent.grocery.text;

    return (<>
        <div class="grocery-shopping-wrapper">
            <Header />

            <div class="banner banner-local-sites">
                <div class="container text-center">
                    <div class="row">
                        <div class="col-12">
                            <h1>Grocery Shopping</h1>
                            <h4>All your service needs streamlined in one place.</h4>
                        </div>
                    </div>
                </div>
            </div>
            <section class="half-image-section expert-section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="badge">Grocery shopping experts</div>
                            <h3>No need to fight the grocery crowds,
                            wrangle the kids, or forget something
                            on your list.</h3>
                            <p class="text">Groceries delivered right to your door wherever you are.  A night in should be filled with top quality food and we are here to help.</p>
                            <p class="text">One time delivery or setting up something recurring we want to lend a hand.  Trusted and fast delivery without the lines and confusing setup.</p>
                        </div>
                        <div class="col-md-6">
                            <div class="image-container">
                                <img src="assets/img/marketing/grocery.png" alt="Illustration" class="fixed-left-image" />
                                <img src="assets/img/marketing/gs_image_1.jpg" alt="Plumber" class="main-image" />
                                <img src="assets/img/marketing/square_circles.png" alt="Dots" class="fixed-square-image" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="full-back-image-section">
                <div class="container">
                    <div class="image-container">
                        <h5>Grocery shopping made easy</h5>
                        <h3>Hire trusted grocery delivery
                        services near you</h3>
                        {/* <button type="buton" class="book-now">Book a professional now</button> */}
                    </div>
                </div>
            </section>

            <section class="services-platform">
                <div class="container">
                    <div class="row service-platform-wrap">
                        <div class="col-12 col-lg-4 services-platform-col">
                            <div class="platform-image">
                                <img src="assets/img/marketing/icons-shopping.svg" alt="icons-shopping" />
                            </div>
                            <div class="platform-content">
                                <h4>Up to 50% cheaper</h4>
                                <p>We work with valued partners to bring you the best price available.</p>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4 services-platform-col">
                            <div class="platform-image">
                                <img src="assets/img/marketing/white-icons-money.svg" alt="white-icons-money" />
                            </div>
                            <div class="platform-content">
                                <h4>Secure cashless payments</h4>
                                <p>From the comfort and ease of your Leasera platform, there's no need for complicated setup.</p>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4 services-platform-col">
                            <div class="platform-image">
                                <img src="assets/img/marketing/icons-calendar.svg" alt="icons-calendar" />
                            </div>
                            <div class="platform-content">
                                <h4>Same day service available</h4>
                                <p>Need those dinner ingredients fast?  We understand the need for on demand delivery.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="half-image-section benifit-section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Benefits of using Leasera service</h5>
                            <h3>Why book grocery delivery
                            through Leasera?</h3>
                            <p class="text">Why use multiple services and various platforms when it can be done all through our seamless integration.  We work with top partners to make the process as uncomplicated as possible.  From a few snacks to a four course dinner scale for any occasion!</p>
                        </div>
                        <div class="col-md-7">
                            <div class="image-container">
                                <img src="assets/img/marketing/grocery.png" alt="Illustration" class="fixed-left-image" />
                                <img src="assets/img/marketing/gs_image_3.jpg" alt="Plumber" class="main-image" />
                                <img src="assets/img/marketing/square_circles.png" alt="Dots" class="fixed-square-image" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ExpertsTestimonial header={header} text={text}/>
            <Newsletter />
            <FooterMain />
        </div>
    </>
    );
}

export default GroceryShopping
