import React from "react";
import { Button, Form } from 'semantic-ui-react';
import set from 'lodash/set'
import { useHistory } from "react-router-dom";
import MoreFilters from "./MoreFilters"
import { updateUrl, getPropertyData } from '../../store/utils';
import SearchBox from '../../components/SearchBox';
import moment from 'moment';

function PropertySearchForm(props) {
    const {
        values,
        setFieldValue,
        propertyFilter,
        isListPage,
        setLoading,
        getPropertiesList
    } = props;

    const history = useHistory();

    const onChangeField = (key, value) => {
        if (key === "googlePlaceId") {
            set(values, key, value.googlePlaceId)
            set(values, "location", value.location)
        } else {
            set(values, key, value)
            setFieldValue(key, value)
        }
        // if (isListPage) {
        //     setLoading(true)
        //     setTimeout(() => onSubmit(propertyFilter, values), 2000);
        // }
    }
    const onSubmit = (initialValues, values) => {
        const newValues = getPropertyData({
            ...initialValues,
            ...values,
            duration: [moment(values.duration[0])
                .format('YYYY-MM-DD')
                .toString(),
            moment(values.duration[1])
                .format('YYYY-MM-DD')
                .toString()]
        })

        isListPage && getPropertiesList(newValues)
        updateUrl({ query: newValues, pathname: 'properties', history })
    }

    return (
        <Form fluid className="renter-form">
            {/* <Form.Group>
                <Form.Field>
                {isListPage &&
                <>
                    <label>{isListPage ? '' : 'Location'}</label>
                    <SearchBox
                        id="searchbox"
                        googlePlaceId={values.googlePlaceId}
                        placeholder='Where are you going?'
                        className="form-control"
                        onChange={newValue => {
                            onChangeField(`googlePlaceId`, newValue)
                        }}
                        hasIcon={isListPage}
                    /> 
                    {isListPage && <i aria-hidden="true" className="search icon" />}
                </Form.Field>
                {isListPage && <MoreFilters
                    values={getPropertyData(values)}
                    setFieldValue={setFieldValue}
                    propertyFilter={propertyFilter}
                    isListPage={isListPage}
                    setLoading={setLoading}
                    getPropertiesList={getPropertiesList}
                    onChangeField={onChangeField} />}
                {!isListPage && <Button
                    primary
                    type="submit"
                    size="huge"
                    // className="homebtn"
                    style={{backgroundImage: "linear-gradient(98deg, #3b1c5a, #374db1 163%)", marginTop: "5px"}}
                    floated="right"
                    onClick={() => {
                        setTimeout(() => onSubmit(propertyFilter, values), 1000)
                    }}
                >
               Get Started On Your Search
                </Button>
                } 
                {
                    isListPage && <Button onClick={() => {
                        setTimeout(() => onSubmit(propertyFilter, values), 1000)
                    }} className="btn-gradient btn-sm ml-2" type='submit'>Search</Button>
                }
            </Form.Group> */}
        </Form>
    )
}

export default PropertySearchForm;