import React from 'react'
import get from 'lodash/get';
import { withApollo } from "react-apollo";
import { propertiesGql, propertyFilterDefaults } from '../../store/property';
import './FeaturedProperties.scss';
import { updateUrl } from '../../store/utils';

class FeaturedProperties extends React.Component {
  constructor(props) {
    super(props)
    this.slider = React.createRef()
  }

  state = {
    current: 0,
    properties: null,
    isloading: false
  }

  componentDidMount() {
    if (!this.state.properties) this.getPropertiesList()
  }

  getPropertiesList = (newValues) => {
    this.setState({ isloading: true })
    console.log(newValues)
    this.props.client.query({
      query: propertiesGql,
      variables: {
        filter: { ...newValues },
      },
    }).then(res => {
      const properties = get(res.data, 'location.edges', []).map(
        property => property.node
      )
      this.setState({ properties: properties, isloading: false })
    }).catch(err => {
      console.log(err)
      this.setState({ isloading: false })
    })
  }

  getNumberOfCardsToShow = () => {
    const CARD_WIDTH = 500
    const PART_OF_SCREEN_FOR_CARDS = 0.6
    return typeof window !== 'undefined'
      ? 2
      : Math.round((this.props.windowWidth * PART_OF_SCREEN_FOR_CARDS) / CARD_WIDTH)
  }

  handleRedirect = () => {
    updateUrl({ query: propertyFilterDefaults, pathname: 'properties', history: this.props.history })
  }

  settings = {
    infinite: false,
    autoplay: false,
    speed: 500,
    slidesToShow: this.getNumberOfCardsToShow(),
    slidesToScroll: 1,
    arrows: false,
    variableWidth: true,
    afterChange: (current) => this.setState({ current }),
  }

  render() {

    return (
      <div></div>
      // <Query query={propertiesGql} variables={{ filter: {} }}>
      //   {({ loading, error, data }) => {
      //     if (loading) return <Loader />
      //     if (error) {
      //       console.error(error)
      //       return null
      //     }
      //     const properties = get(data, 'location.edges', []).map(
      //       property => property.node
      //     )

      //     return (
      //       <div className="featured-properties">
      //         <div className="featured-properties-controls">
      //           <h2 className="featured-properties-title">Featured Properties</h2>
      //           <a className="featured-properties-link pointer" onClick={() => this.handleRedirect()}>
      //             View all
      //             <svg viewBox="64 64 896 896" focusable="false" data-icon="arrow-right" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 0 0 0-48.4z"></path></svg>
      //           </a>
      //           <div className="featured-properties-arrows">
      //             <button
      //               aria-label="Previous"
      //               className={this.state.current === 0 ? "featured-properties-arrows-disabled" : ''}
      //               onClick={() => this.slider.slickPrev()}
      //             >
      //               <img alt="arrow previous slide" src={require('./images/arrow.svg?inline')} />
      //             </button>
      //             <button
      //               aria-label="Next"
      //               className={
      //                 this.state.current === properties.length - this.getNumberOfCardsToShow()
      //                   ? "featured-properties-arrows-disabled"
      //                   : ''
      //               }
      //               onClick={() => this.slider.slickNext()}
      //             >
      //               <img alt="arrow next slide" src={require('./images/arrow.svg?inline')} />
      //             </button>
      //           </div>
      //         </div>
      //         <Slider ref={c => (this.slider = c)} className="featured-properties-slider" {...this.settings}>
      //           {properties.map((ele) => ele && <PropertyCard property={ele} history={this.props.history} />)}
      //         </Slider>
      //       </div>
      //     )
      //   }}
      // </Query>
    )
  }
}

export default withApollo(FeaturedProperties)