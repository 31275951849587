import React, { useEffect, useState } from "react";
import { withApollo } from "react-apollo";
import {
    Icon,
    Grid,
    Card,
    Segment,
    Header as SemanticHeader
} from 'semantic-ui-react';
import { useHistory } from "react-router-dom";
import get from 'lodash/get'
import PropertyMap from "./PropertyMap";
import FooterMain from "../../components/Footer/FooterMain";
import PropertyListBar from '../../components/PropertyListBar';
import { propertiesGql } from '../../store/property';
import Loader from '../../components/Loader';
import { ROUTES } from "../../utils/constants";
import { getSearchResult } from '../PropertySearch/helper'
import PropertyCard from "./PropertyCard"
import './properties.scss'

function Properties({ client }) {
    const [properties, setProperties] = useState([]);
    const [searchParam, setSearchParam] = useState(null);
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [searchStatus, totalSearchStatus] = useState(false);
    const [selectedMarker, setSelectedMarker] = useState({})
    const [url, setTheUrl] = useState('');

    /* eslint-disable */
    useEffect(() => { 
        const params = history.location.state;
        getPropertiesList(params)
        setSearchParam(params)
    },[]);
    /* eslint-enable */

    const setUrl = (data) => {
        setTheUrl('')
        setTheUrl(data)
    }

    const getPropertiesList = (newValues) => {
        totalSearchStatus(getSearchResult(newValues))
        console.log(newValues)
        client.query({
            query: propertiesGql,
            variables: {
                filter: { ...newValues, isBookingDotCom: true,  },
            },
        }).then(res => {
            const properties = get(res.data, 'location.edges', []).map(
                property => property.node
            )
            setProperties(properties)
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    const handleRedirect = (data) => {
        history.push({
            pathname: ROUTES.PROPERTY_DETAIL,
            state: { data: data, units: data.filteredUnits },

        })
    }

    const handleClick = (marker, event) => {
        setSelectedMarker(marker)
    }

    const renderPropertyList = () => {
        if (loading)
            return <Loader />
        if (properties.length > 0)
            return (
                <Grid divided='vertically' className="renter-search-listing">
                    <div className="">
                        <div className="search-listing-wrapper">
                            <div className="card-listing">
                                <Card.Group stackable className="property-search-card-scroll">
                                    {properties.map((e) => {
                                        return <PropertyCard data={e} setUrl={setUrl}handleRedirect={handleRedirect} />
                                    })}
                                </Card.Group>
                            </div>
                            <div className="show-map">
                                <PropertyMap
                                    searchStatus={searchStatus}
                                    properties={properties}
                                    zoom={9}
                                    handleRedirect={handleRedirect}
                                    onClick={handleClick}
                                    selectedMarker={selectedMarker}
                                />
                            </div>
                        </div>
                    </div>
                </Grid>
            )
        else return (
            <Segment.Inline style={{ padding: "6em", color: "grey" }}>
                <Icon name='exclamation' size='massive' />
                <SemanticHeader as='h2' color="grey">No properties found</SemanticHeader>
            </Segment.Inline>)
    }
    return (<>
        {/* <Header /> */}
        <PropertyListBar searchParam={searchParam} getPropertiesList={getPropertiesList} properties={properties} setLoading={setLoading} loading={loading} />
        <Segment textAlign="center">
            {renderPropertyList()}
        </Segment>
        {/* <div id="myModal" class="modal" id="InstructionsModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-content">
                        <div class="modal-header">
                        <span type="button" className="close" data-dismiss="modal" aria-label="Close" >&times;</span>
                        </div>
                        <div class="modal-body">
                <iframe  src={url}></iframe>
                        </div>
                    </div>

                    </div> */}
        {/* eslint-disable */}
        <div id="myModal" class="modal bookingDetailModal" id="InstructionsModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        {/* eslint-enable */}
            <div class="modal-content">
                <div class="modal-header">
                    <span type="button" className="close" data-dismiss="modal" aria-label="Close" >&times;</span>
                </div>
                <div class="modal-body">
                    <iframe src={url} title="Google Maps"></iframe>
                </div>
            </div>
        </div>

        <FooterMain />
    </>
    )
}

export default withApollo(Properties)