import { ApolloClient, ApolloLink, InMemoryCache, HttpLink } from 'apollo-boost';
import { getAuthToken } from './getAuthToken'
import config from '../../config'

export const getLink = (uri) => new HttpLink({ uri: uri || config.GRAPHQL_URL });

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  const token = getAuthToken()
  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: token ? `${token}` : ''
    }
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

export const getClient = (uri) => {
  return new ApolloClient({
    link: authLink.concat(getLink(uri)), // Chain it with the HttpLink
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    }
  })
}