import React, { Component } from "react";
import { withApollo } from "react-apollo";
import {Grid, Header, Image, Segment} from 'semantic-ui-react'
import FooterMain from '../../components/Footer/FooterMain'
import "./RenterInfo.scss";


class RenterInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        return (
            <>
                <Segment style={{ padding: '8em 0em' }} vertical className="top-renter-background">
                    <Header className="top-segment-header" as='h1' textAlign='center' style={{ color: "white" }}>
                    Property Managers
                    </Header>
                    <br />
                    <Header as='h2' textAlign='center' className="top-segment-info">
                    Let us serve you.
                    </Header>
                </Segment>
                <Segment style={{ padding: '8em 0em' }} vertical size="massive" className="middle-renter-empty" >
                    <Grid columns="2" centered >
                        <Grid.Column width="4">
                            <Header>What we did.</Header>
                            <div style={{fontSize:"15px"}}>Leasera is your partner, offering the tools, retention & service solutions you need for a new era of unified, verified consumers. Build loyalty, create access & empower yourselves & your renters.</div>
                        </Grid.Column>
                        <Grid.Column width="4">
                            <Image src='assets\img\Renter\did.jpg' />
                        </Grid.Column>
                    </Grid>
                </Segment>
                <Segment style={{ padding: '8em 0em' }} vertical size="massive" className="middle-renter-background" >
                    <Grid columns="1" centered>
                    <Grid.Column width="8">
                    <Header>Our team.</Header>
                        <div style={{fontSize:"15px"}}>Property managers, property owners and real estate investment 
                        groups can use Leasera to manage full lifecycle of long-term resident with real ease and 
                        operational intelligence. Our analytics and engagement system digitally automates the management 
                        processes including marketing, lead management, long term royalty, expedient turnover and tons 
                        more. Recommendations are driven by platform's proprietary machine learning and predictive analysis 
                        system. Our integrations and data drive decisions and recommendations in real-time for quick review 
                        and easy creation of the most important tasks.<br/><br/>The people we partner with, our clients, 
                        can finally start focusing on relationships, not transactions</div>
                        </Grid.Column>
                    </Grid>
                </Segment>
                <Segment style={{ padding: '8em 0em' }} vertical size="massive" className="middle-renter-empty" >
                    <Grid columns="2" centered >
                        <Grid.Column width="4">
                            <Image src='assets\img\Renter\Team.jpg' />
                        </Grid.Column>
                        <Grid.Column width="4">
                            <Header>Benefits.</Header>
                            <div style={{fontSize:"15px"}}>We have created a new business model for properties and a new lifestyle of empowerment 
                            for consumers. New value, new opportunities, & an all “new life on leasing™”. Leasera is more than just a platform, 
                            it is a lifestyle where opportunities are cosntantly presenting themselves.  <br/> <br/>Leasera is a platform that
                             inspirse users to continue their life journey with the Apartments they love. By giving the Renter added benefits, 
                             Leasera offers the unique opportunity to add additional value to both the Manager and the Renter. Good behavior and 
                             practices are rewarded, leading to more desirable and steadfast renters. <br/> <br/>Even though we've already served 
                             millions of people nation-wide, this is just the beginning of what we have planned. With our growing yet highly vetting 
                             team, the services we're bringing to market will forever change the way people look at extended living.</div>
                        </Grid.Column>
                    </Grid>
                </Segment>
                <Segment style={{ padding: '8em 0em' }} vertical size="massive" className="middle-renter-background-two" >
                    <Grid columns="1" centered>
                    <Grid.Column width="8">
                    <Header>Our managers.</Header>
                        <div style={{fontSize:"15px"}}>As a property manager on our platform you are able to unlock the unique power that Leasera offers. 
                        Regardless of how many doors you own, you can always count on Leasera having your back. Efficiency and cost effective relationships 
                        are initiated from the first click. Using our proprietary bio-graph and profile evaluation rating, we can use secondary background 
                        and financial data, lifestyle goals and personal preferences to better serve you.<br/><br/>Our sophisticated digital approach frees 
                        you to focus precisely and exclusively on information that will improve time, energy and financial efficiencies. We’ve worked hard 
                        to cut unnecessary costs, stress and repeat information requests out of your day so you can find the perfect renters!</div>
                        </Grid.Column>
                    </Grid>
                </Segment>
                
                <FooterMain />
            </>
        )
    }
}

export default withApollo(RenterInfo)