
import React, { Component } from "react";
import Header from "../../components/Header";
import FooterMain from "../../components/Footer/FooterMain";
import Newsletter from "../../components/Newsletter";

class PlatformProducts extends Component {
    render() {
        return (
            <>
                <div class="platform-products-wrapper">
                    <Header />
                    <div class="banner banner-service-provider">
                        <div class="container text-center">
                            <div class="row">
                                <div class="col-12">
                                    <h1>Leasera Products</h1>
                                    <h4>A new era of leasing requires an all new solution. Why not get them all in one place.</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section class="half-image-section expert-section">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="badge">LEASERA LISTINGS</div>
                                    <h3>Unified property listing network simplifies the search across broader accommodation types.</h3>
                                    <p class="text">Whether offering your vacancies to external providers or simply looking for a flexible way of listing both long term and/or short term properties, Leasera gives you flexibility and confidence.</p>
                                    <p class="text">Know that your marketing efforts deliver results and watch as high quality consumers are brought through our seamless workflow from search - to - stay. </p>
                                </div>
                                <div class="col-md-6">
                                    <div class="image-container">
                                        <img src="assets/img/marketing/house-blue.png" alt="Illustration" class="fixed-left-image" />
                                        <img src="assets/img/marketing/pp_image_3.jpg" alt="Plumber" class="main-image" />
                                        <img src="assets/img/marketing/square_circles.png" alt="Dots" class="fixed-square-image" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="half-image-section benifit-section">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="image-container">
                                        <img src="assets/img/marketing/homepage-house.svg" alt="Illustration" class="fixed-left-image" />
                                        <img src="assets/img/marketing/pp_image_2.jpg" alt="Plumber" class="main-image" />
                                        <img src="assets/img/marketing/square_circles.png" alt="Dots" class="fixed-square-image" />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="badge">LEASERA LOYALTY</div>
                                    <h3>Loyalty in the form of points that builds them into a valued and empowered consumer.</h3>
                                    <p class="text">You can’t ask for loyalty if you’re not delivering value. With Leasera’s integrated, on-demand marketplace, not only are consumer reminded every month that they are appreciated.</p>
                                    <p class="text">They have direct access to the services and value they love right from their renters portal. Start winning at the appreciation game and you’ll start winning.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="half-image-section expert-section">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="badge">LEASERA LISTINGS</div>
                                    <h3>Streamlines the leasing process with better tech and a profile they can use elsewhere.</h3>
                                    <p class="text">Whether offering your vacancies to external providers or simply looking for a flexible way of listing both long term and/or short term properties, Leasera gives you flexibility and confidence.</p>
                                    <p class="text">Know that your marketing efforts deliver results and watch as high quality consumers are brought through our seamless workflow from search - to - stay. </p>
                                </div>
                                <div class="col-md-6">
                                    <div class="image-container">
                                        <img src="assets/img/marketing/platform.png" alt="Illustration" class="fixed-left-image" />
                                        <img src="assets/img/marketing/pp_image_1.jpg" alt="Plumber" class="main-image" />
                                        <img src="assets/img/marketing/square_circles.png" alt="Dots" class="fixed-square-image" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Newsletter />
                    <FooterMain />
                </div>
            </>
        );
    }
}
export default PlatformProducts
