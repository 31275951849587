
import React from "react";
import Header from "../../components/Header";
import FooterMain from "../../components/Footer/FooterMain";
import Newsletter from "../../components/Newsletter";
import ExpertsTestimonial from "../../components/ExpertsTestimonial";
import { testimonialContent } from '../../utils/common';

const LocalSites = () => {

    const header = testimonialContent.localSites.header;
    const text = testimonialContent.localSites.text;

    return (<>
        <div class="local-sites-wrapper">
            <Header />
            <div class="banner banner-local-sites">
                <div class="container text-center">
                    <div class="row">
                        <div class="col-12">
                            <h1>Local Sites</h1>
                            <h4>We put the "work" in network so that you don't have to. Find the attractions & benefits of
                            your local community or ask a pro.</h4>
                        </div>
                    </div>
                </div>
            </div>
            <section class="half-image-section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="badge">Professional travel experts</div>
                            <h3>Interact with the guides, and
                            hire them to explore the local
                            attractions in your city</h3>
                            <p class="text">New in town or just trying to stretch your legs a little bit?  Leasera has you covered by offering you advice and guides to make your next adventure even better.</p>
                            <p class="text">Explore the unseen and get helpful advice right here on our platform.  Being in a new place is stressful enough, so let us help make that process easy.</p>
                        </div>
                        <div class="col-md-6">
                            <div class="image-container">
                                <img src="assets/img/marketing/local.png" alt="Illustration" class="fixed-left-image" />
                                <img src="assets/img/marketing/ls_image_3.jpg" alt="Plumber" class="main-image" />
                                <img src="assets/img/marketing/square_circles.png" alt="Dots" class="fixed-square-image" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="full-back-image-section">
                <div class="container">
                    <div class="image-container">
                        <h5>Easily find and plan with your local guide</h5>
                        <h3>Local, trusted travel professionals
                        in your neighborhood</h3>
                        {/* <button type="buton" class="book-now">Book a travel Guide now</button> */}
                    </div>
                </div>
            </section>

            <section class="services-platform">
                <div class="container">
                    <div class="row service-platform-wrap">
                        <div class="col-12 col-lg-4 services-platform-col">
                            <div class="platform-image">
                                <img src="assets/img/marketing/user-sheild.svg" alt="user-sheild" />
                            </div>
                            <div class="platform-content">
                                <h4>Hand picked professional guide</h4>
                                <p>Picking a tour guide you can trust shouldn't be like rolling the dice.  We are here to give you that peace of mind.</p>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4 services-platform-col">
                            <div class="platform-image">
                                <img src="assets/img/marketing/icons-headphone.svg" alt="icons-headphone" />
                            </div>
                            <div class="platform-content">
                                <h4>24/7 Customer support</h4>
                                <p>We offer constant customer support for any of your adventure inquiries.</p>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4 services-platform-col">
                            <div class="platform-image">
                                <img src="assets/img/marketing/icons-calendar.svg" alt="icons-calendar" />
                            </div>
                            <div class="platform-content">
                                <h4>Flexible cancellation</h4>
                                <p>We understand that traveling can be unpredictable and we are here to help in any way possible.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="half-image-section benifit-section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Benefits of using leasera service</h5>
                            <h3>Witness the city through
                            the eyes of a local</h3>
                            <p class="text">From exploring the city of New York to the forests of Yosemite we want you to get the most out of your experience.  See the wonders through the eyes of those that live the experience.  We want to connect you with the best talent so you can have the experience of a lifetime.</p>
                        </div>
                        <div class="col-md-7">
                            <div class="image-container">
                                <img src="assets/img/marketing/local.png" alt="Illustration" class="fixed-left-image" />
                                <img src="assets/img/marketing/ls_image_2.jpg" alt="Plumber" class="main-image" />
                                <img src="assets/img/marketing/square_circles.png" alt="Dots" class="fixed-square-image" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ExpertsTestimonial header={header} text={text} />
            <Newsletter />
            <FooterMain />
        </div>
    </>
    );
}

export default LocalSites
