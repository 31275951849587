// @flow
import { Query } from 'react-apollo'
import gql from 'graphql-tag'

// import type { User as UserResult } from './__generated__/User'

export const personContactDataGql = gql`
  query personContactDataGql {
    person {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ndbId
          firstName
          lastName
          dateOfBirth
          email
          phoneHome
          phoneMobile
        }
      }
    }
  }
`

export class UserContactDataQuery extends Query{}
// export class UserContactDataQuery extends Query<UserResult, {}> {}
