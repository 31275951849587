
import React from "react";
import { Icon } from 'semantic-ui-react';

const Loader = () => {
    return (
        <Icon loading name='spinner' color='purple' size='huge'/>
    )
}

export default Loader;