// @flow
import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { compose } from 'recompose';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import { ROUTES } from '../../utils';
import { loginAction, getAuthToken } from '../../store/auth';
import ForgotPassworForm from './ForgotPassworForm';
import SignIn from "../../containers/SignIn";

const MIN_PASS_LENGTH = 8;

export const FORM_VALIDATE = {
  email: Yup.string()
      .email()
      .required('Required'),
  password: Yup.string().required('Password is required').min(MIN_PASS_LENGTH, 'Password is too short - should be 8 chars minimum.'),
}

class SignInForm extends Component {
    _initAuth = false
    _userPresent = false
    constructor(props) {
        super(props);
        this.state = {
          email: '',
          password: '',
          remember: false,
          showPassword: false,
          location: props.location,
          setFrgtPasStatus: false
        };
    }

    setLoggedStatus = (data) => {
      this.setState({
          isLoggedIn: Boolean(getAuthToken()),
          person: data ? data.data.data : {}
      });
      if (!Boolean(getAuthToken())) {
          this.props.history.push("/")
      }
    };

    handleLogin = async (values, isForgot) => {
          const { location } = this.state;
          try {
              await loginAction(values, location.client).then(() => {
                  toast.success('User logged in successfully.', {
                      position: "top-center",
                      autoClose: 5000,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                  });
                  !location.isPropertyDetail && this.setLoggedStatus()
                  // setLoading(false)
              })
          } catch (error) {
              // setLoading(false)
              toast.error('Email or password is incorect.', {
                  position: "top-center",
                  autoClose: 5000,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
              });
          }
    }

    render() {
    return (<>
      <ToastContainer />
      <div class="main-container signin-container">

      <div class="signup-section">
        <div class="signup-left d-lg-flex">
          <div id="carouselExampleCaptions" class="carousel slide carousel-fade" data-ride="carousel">
            <ol class="carousel-indicators">
              <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
              <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
              <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
              <li data-target="#carouselExampleCaptions" data-slide-to="3"></li>
              <li data-target="#carouselExampleCaptions" data-slide-to="4"></li>
            </ol>
            <div class="carousel-inner">
             {/* {[...Array(number)].map((elementInArray, index) => (
              <div class="carousel-item active" key={index}>
                <div class="content-wrap">
                  <img src="/assets/img/Auth/bg-signin-left.jpg" alt="Left bitmap" class="full-section-image" />
                  <NavLink exact to={ROUTES.LANDING} activeClassName="active" className="brand-image">
                    <img src="assets/img/logo.png" height="45" alt="Logo" />
                  </NavLink>
                  <div class="text">
                    <p class="big">Welcome to a new life on leasing.</p>
                    <p class="small">Find a new home, explore long-term furnished housing, or dream big by browsing
                      vacation
                      spots. </p>
                  </div>
                </div>
              </div>
             ))} */}
              <div class="carousel-item active">
                <div class="content-wrap">
                  <img src="/assets/img/Auth/bg-signin-left.jpg" alt="Left bitmap" class="full-section-image" />
                  <a href={ROUTES.LANDING} className="brand-image">
                    <img src="assets/img/logo-white-2.png" alt="Logo" />
                  </a>
                  <div class="text">
                    <p class="big">Welcome to a new life on leasing.</p>
                    <p class="small">Find a new home, explore long-term furnished housing, or dream big by browsing
                      vacation
                      spots. </p>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="content-wrap">
                  <img src="/assets/img/Auth/bg-signin-left.jpg" alt="Left bitmap" class="full-section-image" />
                  <a href={ROUTES.LANDING} activeClassName="active" className="brand-image">
                  <img src="assets/img/logo-white-2.png" alt="Logo" />
                  </a>
                  <div class="text">
                    <p class="big">Renter empowerment for the modern age.</p>
                    <p class="small">We've worked tirelessly to provide access to the services and brands we all love. </p>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="content-wrap">
                  <img src="/assets/img/Auth/bg-signin-left.jpg" alt="Left bitmap" class="full-section-image" />
                  <a href={ROUTES.LANDING} activeClassName="active" className="brand-image">
                  <img src="assets/img/logo-white-2.png" alt="Logo" />
                  </a>
                  <div class="text">
                    <p class="big">Leasera is your best fit.</p>
                    <p class="small">We have created a new business model for properties and a new lifestyle of empowerment. </p>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="content-wrap">
                  <img src="/assets/img/Auth/bg-signin-left.jpg" alt="Left bitmap" class="full-section-image" />
                  <a href={ROUTES.LANDING} activeClassName="active" className="brand-image">
                  <img src="assets/img/logo-white-2.png" alt="Logo" />
                  </a>
                  <div class="text">
                    <p class="big">Enter a new era of
                                rental management.</p>
                    <p class="small">Thanks to Leasera, you
                  can now handle flexible leasing opportunities and engage a supportive network.</p>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="content-wrap">
                  <img src="/assets/img/Auth/bg-signin-left.jpg" alt="Left bitmap" class="full-section-image" />
                  <a href={ROUTES.LANDING} activeClassName="active" className="brand-image">
                  <img src="assets/img/logo-white-2.png" alt="Logo" />
                  </a>
                  <div class="text">
                    <p class="big">A Marketplace of Value.</p>
                    <p class="small">Win the hearts of more consumers. </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="login-container">
        <Formik
              initialValues={{ email: '', password: '', remember: false }}
              onSubmit={async values => {
                  // setLoading(true)
                  await this.handleLogin(values)
              }}
              validationSchema={Yup.object().shape({ ...FORM_VALIDATE })}
              render={({ values, setFieldValue, errors, touched, handleSubmit, isSubmitting, showPassword }) => (
          <Form class="content">
            <div class="signup-top-section">
              {/* <h1>Sign In as a {`${location.isRenter ? 'renter' : 'property manager'}`}</h1> */}
              <h1>Sign In</h1>
              <p class="signup-detail">Please sign in to Leasera with your original account details.</p>

              {/* <div class="form-group">
                <label for="email">Email</label>
                <input type="email" class="form-control is-invalid" id="email"
                  placeholder="Please enter your email address..." />
                <div class="invalid-feedback">Email id is not correct.</div>
              </div>
              <div class="form-group">
                <label for="userPassword">Password</label>
                <input type="password" class="form-control" id="userPassword"
                  placeholder="Please enter your password..." />
                <div class="invalid-feedback">Password is not correct.</div>
              </div> */}
               <div className="form-group">
                <label htmlFor="email">Email</label>
                <Field className={`form-control ${errors.email && 'is-invalid'}`} type="email" name="email" placeholder="Please enter your email address..." />
                <ErrorMessage className="invalid-feedback" name="email" component="div" />
              </div>
              <div className="form-group">
              <label htmlFor="password">Password</label>
              {this.state.showPassword ? <i class="bi bi-eye" onClick={() => this.setState({ showPassword: false })}></i> : 
                  <i class="fa fa-eye-slash" aria-hidden="true" onClick={() => this.setState({ showPassword: true })}></i>}
                <Field className={`form-control rounded-0 ${errors.password && 'is-invalid'}`} type={values.showPassword ? 'text' : 'password'} name="password" 
                    error={touched.password && errors.password ?
                                  { content: errors.password }
                                  : null} placeholder="Please enter your password..."
                    // icon={this.state.showPassword ? <i class="bi bi-eye" onClick={() => this.setState({ showPassword: false })}></i> : 
                    // <i class="fa fa-eye-slash" aria-hidden="true" onClick={() => this.setState({ showPassword: true })}></i>
                        //  <Icon
                        //      name={this.state.showPassword ? 'eye slash' : 'eye'}
                        //      link
                        //      onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                        //  />
                    />
                <ErrorMessage className="invalid-feedback" name="password" component="div" />
              </div>                                                                                  

              <div class="last-row">
              {/* eslint-disable */}
              <a href="#" class="term-text" title="Forgot Password?" data-toggle="modal"
                  data-target="#forgotPassword">Forgot Password?</a>
              {/* eslint-enable */}
              </div>
              <button type="submit" class="btn btn-block btn-color" disabled={isSubmitting}
              >Sign In</button>
              {/* <p class="signup-text">Don&#8217;t have an account?&nbsp;<button onClick={() => this.props.history.push({
                    pathname: ROUTES.SIGNUP_FORM,
                    state: { isRenterSignUp: location.isRenter ? RENTER : MANAGER }
                })}
                  title="Sign Up">Sign Up</button></p> */}
               {/* <p class="signup-text">Don&#8217;t have an account?&nbsp;<button data-toggle="modal" 
                  data-target="#signin" title="Sign Up">Sign Up</button></p> */}
            </div>

          </Form>)} />
        </div>
      </div>

    </div>
    <ForgotPassworForm
            setLoggedStatus={this.setLoggedStatus}
            history={this.props.history}
    />
    <SignIn />
      </>
      )
    }
}

export default compose(withRouter, withApollo)(SignInForm);
