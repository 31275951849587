// @flow
import React from 'react';
import { Input } from 'semantic-ui-react';
import { compose, withProps, lifecycle } from 'recompose'
import StandaloneSearchBox from 'react-google-maps/lib/components/places/StandaloneSearchBox'
import { withScriptjs, withGoogleMap } from 'react-google-maps';
import { waitForGlobal } from '../../store/utils';
import mixpanel from 'mixpanel-browser';
import './SearchBox.scss'
const SearchBox = ({
  locationName,
  bounds = [],
  placeholder,
  hasIcon,
  onSearchBoxMounted,
  onPlacesChanged,
  notLoaded = false,
  id,
  onClearPlaces,
 }) => {
  return (
    <div className={`SearchBox ${hasIcon ? 'SearchBox__with_icon' : ''}`}>
      {notLoaded ? (
        <Input id={id} placeholder={placeholder} disabled />
      ) : (<StandaloneSearchBox
        bounds={bounds.length ? bounds : null}
        ref={onSearchBoxMounted}
        onPlacesChanged={onPlacesChanged} >
        <Input
          type="text"
          className="form-control"
          onClick={mixpanel.track('Marketing Page Action', {
            'ndbId': "0",
            'sub': 'Search Input Clicked'
          })}
          id={id}
          placeholder="Where are you going?"
          value={locationName}
          onChange={({ target: { value } }) => onClearPlaces(value)} />
      </StandaloneSearchBox>)}
    </div>
  )
}

export default compose(
  withProps(props => ({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyCKK1IA0tSg1Fg0foAc3N8wwHMvTHl_Yj4&libraries=places&language=en',
    loadingElement: <SearchBox {...props} notLoaded />,
    containerElement: <div />,
    mapElement: <div />,
  })),
  lifecycle({
    componentWillMount() {
      const refs = {}
      this.setState({
        places: [],
        onSearchBoxMounted: ref => {
          refs.searchBox = ref
        },
        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces()
          this.setState({
            locationName: places[0] && places[0].formatted_address,
            location: [places[0] && places[0].geometry.location.lat(), places[0] && places[0].geometry.location.lng()]
          })
          const placeId = places[0] ? places[0].place_id : null
          this.props.onChange({
            googlePlaceId: placeId,
            location: [places[0] && places[0].geometry.location.lat(), places[0] && places[0].geometry.location.lng()]
          })
        },
        onClearPlaces: (value) => {
          this.setState({ locationName: value })
          if (value === "")
            this.props.onChange({
              googlePlaceId: null,
              location: null
            })
        },
      })
    },
    componentDidMount() {
      if (this.props.googlePlaceId?.googlePlaceId || this.props.googlePlaceId) {
        waitForGlobal('google', () => {
          const top = 49.3457868 // north lat
          const left = -124.7844079 // west long
          const right = -66.9513812 // east long
          const bottom = 24.7433195 // south lat
          const bounds = new window.google.maps.LatLngBounds()
          bounds.extend(new window.google.maps.LatLng(top, left))
          bounds.extend(new window.google.maps.LatLng(top, right))
          bounds.extend(new window.google.maps.LatLng(bottom, left))
          bounds.extend(new window.google.maps.LatLng(bottom, right))
          this.setState({ bounds })

          new window.google.maps.Geocoder().geocode(
            { placeId: this.props.googlePlaceId.googlePlaceId || this.props.googlePlaceId },
            places => {
              return this.setState({
                locationName: places[0] && places[0].formatted_address,
                location: [places[0] && places[0].geometry.location.lat(), places[0] && places[0].geometry.location.lng()]
              })
            }
          )
        })
      }
    },
  }),
  withScriptjs,
  withGoogleMap
)(SearchBox)
