import React from 'react'
import { withApollo } from "react-apollo";
import { compose } from 'recompose'
import { ROUTES, RENTER, MANAGER } from '../../utils';
import { withRouter } from "react-router-dom";
import mixpanel from 'mixpanel-browser';
function SignIn(props) {
    // const { client, isPropertyDetail } = props;
    let isRenter = true;
    
    const changeUser = (value) => {
      if(value === false){
        mixpanel.track('Sign Up Action', {
          'ndbId': "0",
          'sub': 'Manager Sign Up Clicked'
        });
      }
      if(value === true){
        mixpanel.track('Sign Up Action', {
          'ndbId': "0",
          'sub': 'Renter Sign Up Clicked'
        });
      }
        isRenter = value;
        setLogin();
    }

    const setLogin = () => {
        window.$('#signin').modal('toggle');
        props.history.push({
          pathname: ROUTES.SIGNUP_FORM,
          state: { isRenterSignUp: isRenter ? RENTER : MANAGER }
        })
        // props.history.push({
        //     pathname: ROUTES.SIGNIN,
        //     client: client,
        //     isPropertyDetail: isPropertyDetail,
        //     isRenter: isRenter,
        // })
    }
    return (
    <>
     <div class="modal fade" id="signin" tabIndex="-1" role="dialog" aria-labelledby="signinModal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content signin-bg">
              <div class="modal-header">
                <h5 class="modal-title" id="signinModalLabel">{props.currentAction === "signUp" ? "Welcome to Leasera": "Sign In to Leasera"}</h5>
                <p class="modal-sutitle">Please choose your preferred role</p>
              </div>
              <div class="modal-body">
                <a href={props.currentAction =="signIn" && "https://demo.manager.leasera.com/#/" }  class="btn modal-large-btn" onClick={() => props.currentAction =="signUp" && changeUser(false)}>
                  <div class="image-section">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                      <g fill="none" fillRule="evenodd">
                          <g>
                              <g>
                                  <g>
                                      <g>
                                          <g>
                                              <g>
                                                  <path d="M0 0L19 0 19 19 0 19z" transform="translate(-108 -2302) translate(0 2080) translate(100 209) translate(0 5) translate(8 8) translate(3 3)"/>
                                                  <path fill="#5E6267" fillRule="nonzero" d="M15.833 15.833c0 .438-.354.792-.791.792H3.958c-.437 0-.791-.354-.791-.792V8.708H.792l8.175-7.432c.302-.275.764-.275 1.066 0l8.175 7.432h-2.375v7.125zm-9.896-5.541c0 1.967 1.595 3.562 3.563 3.562 1.968 0 3.563-1.595 3.563-3.562h-1.584c0 1.093-.886 1.979-1.979 1.979s-1.98-.886-1.98-1.98H5.939z" transform="translate(-108 -2302) translate(0 2080) translate(100 209) translate(0 5) translate(8 8) translate(3 3)"/>
                                              </g>
                                          </g>
                                      </g>
                                  </g>
                              </g>
                          </g>
                      </g>
                  </svg>
                  </div>
                  <div class="text-container">
                    <div class="text-heading">Property Manager</div>
                    <div class="text-des">Manage your listings and other stuff</div>
                  </div>
                </a>
                <a href={props.currentAction =="signIn" && "https://demo.renter.leasera.com/#/" }   class="btn modal-large-btn" onClick={() => props.currentAction =="signUp" &&changeUser(true)}>
                  <div class="image-section">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                      <g fill="none" fillRule="evenodd">
                          <g>
                              <g>
                                  <g>
                                      <g>
                                          <g>
                                              <path d="M0 0L22.8 0 22.8 22.8 0 22.8z" transform="translate(-80 -290) translate(50 260) translate(30 30) translate(3.6 3.6)"/>
                                              <path fill="#5E6267" fillRule="nonzero" d="M9.797 10.988l7.543-7.543 2.015 2.015-1.343 1.343 2.015 2.015-3.358 3.36-2.015-2.016-2.84 2.84c1.08 2.08.483 4.637-1.406 6.023-1.889 1.386-4.507 1.189-6.166-.466-1.66-1.658-1.862-4.28-.474-6.171 1.388-1.892 3.95-2.487 6.03-1.4zm-.854 5.555c.494-.477.692-1.183.518-1.847-.173-.665-.692-1.184-1.357-1.357-.664-.174-1.37.024-1.847.518-.72.745-.71 1.93.023 2.663.733.733 1.918.743 2.663.023z" transform="translate(-80 -290) translate(50 260) translate(30 30) translate(3.6 3.6)"/>
                                          </g>
                                      </g>
                                  </g>
                              </g>
                          </g>
                      </g>
                  </svg>
                  </div>
                  <div class="text-container">
                    <div class="text-heading">Renter</div>
                    <div class="text-des">Manage your property and reward points</div>
                  </div>
                </a>
                {/* <button type="button" class="btn btn-block btn-color" onClick={setLogin}>{`Proceed as a ${isRenter ? 'renter' : 'manager'}`}</button> */}
              </div>
            </div>
        </div>
      </div>
      </>
    )
}

export default compose(withRouter, withApollo)(SignIn);
