import React from 'react';
import { ErrorMessage, useField } from 'formik';
// import placeholder from '../../assets/img/undraw/undraw_apartment.png';

export const CustomDropDown = ({ label, data, text, obj, ...props }) => {
  const [field, meta] = useField(props);

  /* eslint-disable */
  const renderDropdown = () => {
    switch(data) {
      case "orderedUnits":
        return obj.map((u, i) => {
          if(u.status === "vacant") {
            return <option key={`${i}${u}`} value={u.id}>Unit {u.number}</option>
          }
        });
      case "tourType":
        return obj.map((t, i) => {
          return <option key={`${i}${t}`} value={t.node.code}>{t.node.tourType.charAt(0).toUpperCase() + t.node.tourType.slice(1)}</option>
        })   
      default:
        return obj.map((t, i) => {
          return <option key={`${i}${t}`} value={t}>{t}</option>
        })
    }
  };
  /* eslint-enable */


  return (
    <div className="has-validation">
      <div className="row">
        <div className="col-sm-6">
          <label htmlFor="units" className="rld-single-input">{text}</label>
        </div>
        <div className="col-sm-6">
          <select
            {...field}
            {...props}
            className={`rld-single-input form-control ${meta.touched && meta.error && "form-error-input"}`}
            name={field.name}

            id={field.name}
            // style={{background: "#F3F3F3"}}
          >
          <option value="" default></option>
          {renderDropdown()}
        </select>
        </div>
      </div>
      <ErrorMessage
        component="div"
        name={field.name}
        className="form-error-message"
        style={{textAlign: "right"}}
      />
    </div>
  )
}