// @flow
import gql from 'graphql-tag'


export const propertiesGql = gql`
query location($filter: LocationInput) {
  location(filter: $filter) {
    edges {
      node{
        id
        name
        locationId
        organizationId
        totalUnits
        totalOccupied
        isBookingDotCom
        customId
        walkScore
        photos
        addresses {
          streetOne
          zip
          city
          state
        }
        fees
        amenities
        depositRules
        applicationConfig
        maxOccupancy
        isDelete
        yearBuilt
        createdAt
        updatedAt
        contact
        totalUnits
        location
        filteredUnits
        totalBaths
        totalPrices
        totalBeds
        totalSizes
        description
        beds
        baths
        prices
        sizes
        hotelId
        review
        address
        hotelUrl
        stars
        fees
        totalPrices
        hotelUrl
      }
    }
  }
}
`
export const unitsGql = gql`
query unit($input: UnitInput!) {
  unit(filter: $input) {
    edges {
      node {
        id
        locationId
        unitTypesId
        photos
        amenities
        floor
        building
        number
        description
        bathrooms
        bedrooms
        sqft
        price
        petsPermitted
        isDelete
        createdAt
        updatedAt
      }
    }
  }
}
`