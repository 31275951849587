export const ROUTES = {
    LANDING: '/',
    ABOUT_US: '/about-us',
    CONTACT_US: '/contact-us',
    FAQ: '/faq',
    FORGOT_PASSWORD: '/forgot-password',
    MANAGER_INFO: '/manager-info',
    RENTER_INFO: '/renter-info',
    PRIVACY: '/privacy',
    PROPERTY_SEARCH: '/property-search',
    SERVICE_INFO: '/service-info',
    RENT: '/rent',
    TERMS: '/terms',
    RENTAL_AGREEMENT: '/rental-agreement',
    SIGNUP: '/sign-up',
    SIGNUP_FORM: '/sign-up-form',
    PROPERTIES: '/properties',
    BLOG: '/blog',
    RENTER: '/renter',
    MANAGER: '/manager',
    SERVICE: '/service-provider',
    PROPERTY_DETAIL: '/property-details',
    SIGNIN: '/sign-in',
    RENTER_SIGNIN: 'renter/sign-in',
    PLATFORM_PRODUCTS: '/platform-products',
    GROCERY_SHOPPING: '/grocery-shopping',
    LOCAL_SITES: '/local-sites',
    MARKETING_PROFESSIONAL: '/marketing-professional',
    PET_SERVICES: '/pet-services',
    MarketingDemo: '/marketing-demo',
};

export const MIN_YEAR = 18
export const MAX_PRICE = 2800
export const MIN_PRICE = 1
export const SHORT_MAX_PRICE = 500
export const HOTEL = 'hotel'
export const MOTEL = 'motel'
export const HOSTEL = 'hostel'
export const SHORT_TERM_PROPERTIES = [HOTEL, MOTEL, HOSTEL]
export const TOKEN = 'token'
export const REFRESH_TOKEN = 'refreshToken'
export const EXPIRE_TIME = 'expireTime'
export const RELOAD = 'reload'
export const LOGIN = 'login'
export const SESSION_TIMEOUT_THRESHOLD = 300
export const USER_EVENT = 'userLoggedStatus'
export const USER_ROLE_EVENT = 'userRoleStatus'
export const RENTER = 'renter'
export const MANAGER = 'manager'
export const SERVICEPROFESSIONAL = 'serviceProfessional'
export const SERVICEPROVIDER = 'serviceProvider'


export const PERSON_FN_URL = `https://service-person-2tisrzg7pa-uc.a.run.app/person/graphql`
export const SEARCH_PROP = `https://service-location-search-2tisrzg7pa-uc.a.run.app/location_search/graphql`
export const CONTACT = `https://service-contact-us-2tisrzg7pa-uc.a.run.app/contact_us/graphql`
export const MICROSITE_INFO = `https://service-microsite-2tisrzg7pa-uc.a.run.app/microsite/graphql`;
