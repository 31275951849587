// @flow
import React from 'react';
import { Grid, Form, Dropdown, Radio } from 'semantic-ui-react';
import { withApollo } from "react-apollo";
import { Formik } from 'formik';
import PropertySearchForm from '../../containers/PropertySearch/PropertySearchForm';
import { propertyFilterDefaults } from '../../store/property';
import Header from '../Header/index';

function PropertyListBar({ client, searchParam, properties, setLoading, loading, getPropertiesList }) {
    return (
        <>
            <div className="renter-listing-header">
                <Header />
            </div>
            <div className="renter-header-search" >
                <div className="primary container">
                    <Formik
                        initialValues={{ ...propertyFilterDefaults }}
                        render={({ setValues, values, setFieldValue }) => {
                            return (<>
                                {searchParam &&
                                    <PropertySearchForm
                                        propertyFilter={searchParam}
                                        client={client}
                                        setValues={setValues}
                                        values={searchParam}
                                        setFieldValue={setFieldValue}
                                        isListPage={true}
                                        setLoading={setLoading}
                                        loading={loading}
                                        getPropertiesList={getPropertiesList}
                                    />}
                            </>
                            )
                        }}
                    />
                </div>
                {/* More Filters Form */}
                <div className="primary container">
                    <Form fluid className="renter-form pt-0">
                        <Form.Group>
                            <Form.Dropdown
                                placeholder='Services'
                                text="Services"
                                className="link item services-type"
                                fluid
                                selection>
                                <Dropdown.Menu className="inner-dropdown">
                                    <div className="dropdown-option">Option 1</div>
                                    <div className="dropdown-option">Option 2</div>
                                    <div className="dropdown-option">Option 3</div>
                                </Dropdown.Menu>
                            </Form.Dropdown>
                            <Form.Dropdown
                                placeholder='Type of Properties'
                                text="Type of Properties"
                                className="link item services-type"
                                fluid
                                selection>
                                <Dropdown.Menu className="inner-dropdown">
                                    <div className="dropdown-option">Option 1</div>
                                    <div className="dropdown-option">Option 2</div>
                                    <div className="dropdown-option">Option 3</div>
                                </Dropdown.Menu>
                            </Form.Dropdown>
                            <div className="renter-search-listing-types">
                                <Form.Field>Type of listings</Form.Field>
                                <Form.Field>
                                    <Radio
                                        label='Furnished'
                                        name='radioGroup'
                                        value='this'
                                        checked="checked"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Radio
                                        label='Unfurnished'
                                        name='radioGroup'
                                        value='that'
                                    />
                                </Form.Field>
                            </div>
                            <Form.Input
                                type="number"
                                placeholder='Size (sq.ft)'
                                className="ml-2 mxw-120"
                            />
                        </Form.Group>
                    </Form>
                </div>
            </div>
            <div className="renter-sub-header">
                <div className="secondary container">
                    <h5>{properties.length} properties found</h5>
                    {/* <Grid.Column textAlign="right"><Radio label='Show map' toggle /></Grid.Column> */}
                </div>
            </div>
        </>
    )
}

export default withApollo(PropertyListBar);
