import React from "react";


const ExpertsTestimonial = (props) => {
  return (<>
    <section class="service-guide">
      {/* <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="top-header">
              <h5>Top leasera experts</h5>
              <h3>{header}</h3>
              <p class="text">{text}</p>
            </div>
            <div id="carouselExampleCaptions2" class="carousel slide" data-ride="carouse2">

              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="card-carousel">
                    <div class="content-rw">
                      <div class="header-wrap d-flex">
                        <img src="assets/img/marketing/guide-1.jpg" alt="Left bitmap"
                          class="full-section-image" />
                        <div class="reviewr-detail">
                          <h5>Michael Cain</h5>
                          <h6>Seattle, WA</h6>
                        </div>
                      </div>
                      <div class="strip-rw">
                        <div class="left-side">
                          <img src="assets/img/marketing/icons-badge-blue.svg" alt="badge" />
                          <span>Elite expert</span>
                        </div>
                        <div class="rating-stars">
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star"></span>
                          <span class="fa fa-star"></span>
                        </div>
                      </div>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sapien
                      nisi, sceleris eget nisl non, ultricies consectetur sapien. Sed lacus est.</p>
                    </div>
                    <div class="content-rw">
                      <div class="header-wrap d-flex">
                        <img src="assets/img/marketing/guide-1.jpg" alt="Left bitmap"
                          class="full-section-image" />
                        <div class="reviewr-detail">
                          <h5>Michael Cain</h5>
                          <h6>Seattle, WA</h6>
                        </div>
                      </div>
                      <div class="strip-rw">
                        <div class="left-side">
                          <img src="assets/img/marketing/icons-badge-blue.svg" alt="badge" />
                          <span>Elite expert</span>
                        </div>
                        <div class="rating-stars">
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star"></span>
                          <span class="fa fa-star"></span>
                        </div>
                      </div>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sapien
                      nisi, sceleris eget nisl non, ultricies consectetur sapien. Sed lacus est.</p>
                    </div>
                    <div class="content-rw">
                      <div class="header-wrap d-flex">
                        <img src="assets/img/marketing/guide-1.jpg" alt="Left bitmap"
                          class="full-section-image" />
                        <div class="reviewr-detail">
                          <h5>Michael Cain</h5>
                          <h6>Seattle, WA</h6>
                        </div>
                      </div>
                      <div class="strip-rw">
                        <div class="left-side">
                          <img src="assets/img/marketing/icons-badge-blue.svg" alt="badge" />
                          <span>Elite expert</span>
                        </div>
                        <div class="rating-stars">
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star"></span>
                          <span class="fa fa-star"></span>
                        </div>
                      </div>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sapien
                      nisi, sceleris eget nisl non, ultricies consectetur sapien. Sed lacus est.</p>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="card-carousel">
                    <div class="content-rw">
                      <div class="header-wrap d-flex">
                        <img src="assets/img/marketing/guide-1.jpg" alt="Left bitmap"
                          class="full-section-image" />
                        <div class="reviewr-detail">
                          <h5>Michael Cain</h5>
                          <h6>Seattle, WA</h6>
                        </div>
                      </div>
                      <div class="strip-rw">
                        <div class="left-side">
                          <img src="assets/img/marketing/icons-badge-blue.svg" alt="badge" />
                          <span>Elite expert</span>
                        </div>
                        <div class="rating-stars">
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star"></span>
                          <span class="fa fa-star"></span>
                        </div>
                      </div>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sapien
                      nisi, sceleris eget nisl non, ultricies consectetur sapien. Sed lacus est.</p>
                    </div>
                    <div class="content-rw">
                      <div class="header-wrap d-flex">
                        <img src="assets/img/marketing/guide-1.jpg" alt="Left bitmap"
                          class="full-section-image" />
                        <div class="reviewr-detail">
                          <h5>Michael Cain</h5>
                          <h6>Seattle, WA</h6>
                        </div>
                      </div>
                      <div class="strip-rw">
                        <div class="left-side">
                          <img src="assets/img/marketing/icons-badge-blue.svg" alt="badge" />
                          <span>Elite expert</span>
                        </div>
                        <div class="rating-stars">
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star"></span>
                          <span class="fa fa-star"></span>
                        </div>
                      </div>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sapien
                      nisi,sceleris eget nisl non, ultricies consectetur sapien. Sed lacus est.</p>
                    </div>
                    <div class="content-rw">
                      <div class="header-wrap d-flex">
                        <img src="assets/img/marketing/guide-1.jpg" alt="Left bitmap"
                          class="full-section-image" />
                        <div class="reviewr-detail">
                          <h5>Michael Cain</h5>
                          <h6>Seattle, WA</h6>
                        </div>
                      </div>
                      <div class="strip-rw">
                        <div class="left-side">
                          <img src="assets/img/marketing/icons-badge-blue.svg" alt="badge" />
                          <span>Elite expert</span>
                        </div>
                        <div class="rating-stars">
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star"></span>
                          <span class="fa fa-star"></span>
                        </div>
                      </div>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sapien
                      nisi,
                                                sceleris eget nisl non, ultricies consectetur sapien. Sed lacus est.</p>
                    </div>
                  </div>
                </div>

              </div>
              <ol class="carousel-indicators">
                <li data-target="#carouselExampleCaptions2" data-slide-to="0" class="active"></li>
                <li data-target="#carouselExampleCaptions2" data-slide-to="1"></li>
                <li data-target="#carouselExampleCaptions2" data-slide-to="2"></li>
                <li data-target="#carouselExampleCaptions2" data-slide-to="3"></li>
                <li data-target="#carouselExampleCaptions2" data-slide-to="4"></li>
              </ol>
            </div>
          </div>
        </div>
      </div> */}
    </section>

  </>
  );
}

export default ExpertsTestimonial
