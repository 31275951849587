
import { HOTEL, MOTEL, HOSTEL } from './constants'

export const propertyTypeOptions = [
    {
        key: 'All',
        value: null,
        text: 'All',
    },
    {
        key: 'House',
        value: 'house',
        text: 'House',
    },
    {
        key: 'Apartment',
        value: 'apartment',
        text: 'Apartment',
    },
    {
        key: 'Hotel',
        value: HOTEL,
        text: 'Hotel',
    },
    {
        key: 'Hostel',
        value: HOSTEL,
        text: 'Hostel',
    },
    {
        key: 'Motel',
        value: MOTEL,
        text: 'Motel',
    },
    {
        key: 'Other',
        value: 'other',
        text: 'Other',
    },
]
// export const propertyType = 'propertyType'
export const propertyType = {
    MIN: [
        { key: '1', text: '1', value: 1 },
        { key: '2', text: '2', value: 2 },
        { key: '3', text: '3', value: 3 },
        { key: '4', text: '4', value: 4 },
        { key: '5', text: '5', value: 5 },
        { key: '6', text: '6', value: 6 },
    ],
    MAX: [
        { key: '1', text: '1', value: 1 },
        { key: '2', text: '2', value: 2 },
        { key: '3', text: '3', value: 3 },
        { key: '4', text: '4', value: 4 },
        { key: '5', text: '5', value: 5 },
        { key: '6', text: '6', value: 6 },
    ]
}

export const MIN = "MIN";
export const MAX = "MAX";

export const priceOption = {
    MIN: [
        // { key: '0', text: 'No min price', value: 0 },
        { key: '500', text: '500', value: 500 },
        { key: '700', text: '700', value: 700 },
        { key: '900', text: '900', value: 900 },
        { key: '1100', text: '1,100', value: 1100 },
        { key: '1300', text: '1,300', value: 1300 },
        { key: '1500', text: '1,500', value: 1500 },
    ],
    MAX: [
        // { key: '1100', text: '1,100', value: 1100 },
        { key: '1300', text: '1,300', value: 1300 },
        { key: '1500', text: '1,500', value: 1500 },
        { key: '1700', text: '1,700', value: 1700 },
        { key: '1900', text: '1,900', value: 1900 },
        { key: '2100', text: '2,100', value: 2100 },
        { key: '0', text: 'No Max price', value: 0 },
    ]
}

export const testimonialContent = {
    grocery: {
        header: 'Our grocery experts',
        text: 'We take pride in serving your needs in all new way.'
    },
    pets: {
        header: 'Our pet professionals!',
        text: 'Your family is in great hands from those that care and are excited to serve.'
    },
    maintenance: {
        header: 'Our maintenance professionals!',
        text: 'Hear why people love the service and the people providing those services.'
    },
    localSites: {
        header: 'Some pro travel guide experts',
        text: 'To be uploaded'
    }
}