// @flow
import moment from 'moment'
import gql from 'graphql-tag'

// import type { PropertyFilter as PropertyFilterResult } from './__generated__/PropertyFilter'
export const propertyFilterSchemaGql = gql`
  type PropertyFilter {
    googlePlaceId: String
    cityId:String
    isBookingDotCom:Boolean
    startDate: String
    endDate: String
    propertyType: String
    occupants: PropertyFilterOccupants
    place: PropertyFilterPlace
  }

  type PropertyFilterOccupants {
    rooms: Int
    adults: Int
    children: Int
  }

  type PropertyFilterPlace {
    price: PropertyFilterPlacePrice
    bedrooms: Int
    bathrooms: Int
    size: Int
    petsAllowed: Boolean
    parking: Boolean
    terrace: Boolean
  }

  type PropertyFilterPlacePrice {
    min: Int
    max: Int
  }

  extend type Query {
    propertyFilter: PropertyFilter
  }
`
export const propertyFilterDefaults = {
  googlePlaceId: null,
  isBookingDotCom:false,
  cityId:null,
  totalPrices: null,
  totalBaths: null,
  location: [47.6062095,-122.3320708],
  totalSizes: null,
  totalBeds: null,
  duration: [
    moment().format('YYYY-MM-DD'),
    moment().add(1, 'days')
      .format('YYYY-MM-DD')]
}

export const propertyFilterGql = gql`
  query PropertyFilter {
    propertyFilter @client {
      isBookingDotCom
      cityId
      googlePlaceId
      startDate
      endDate
      propertyType
      occupants {
        rooms
        adults
        children
      }
      place {
        price {
          min
          max
        }
        bedrooms
        bathrooms
        size
        petsAllowed
        parking
        terrace
      }
    }
  }
`