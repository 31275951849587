import React, { Component } from "react";
import { withApollo } from "react-apollo";
import FooterMain from '../../components/Footer/FooterMain'
// import "./AboutUs.scss";
import Header from "../../components/Header";
import Newsletter from "../../components/Newsletter";

class AboutUs extends Component {
    // constructor(props) {
    //     super(props)
    //     this.state = {

    //     }
    // }
    render() {
        return (
            <>
                <Header />
                <div class="banner banner-service-provider about-banner">
                    <div class="container text-center">
                        <div class="row">
                            <div class="col-12">
                                <h1>About Leasera</h1>
                                <h4>We help renters and property managers live and work better.</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <section class="half-image-section">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="badge">What we did</div>
                                <h3>Powerful, multi-channel resident
                                engagement and lifecycle
              management platform</h3>
                                <p class="text">The process of finding a new home can really suck. Additionally, managing properties and units with clunky software can be super frustrating.</p>
                                <p class="text">Through intelligent, independent integration we are trying to take a fractured, manually intensive and time consuming industry into the future. Don't worry though, we had fun doing it.</p>
                            </div>
                            <div class="col-md-6">
                                <div class="image-container">
                                    <img src="assets/img/marketing/house.png" alt="Illustration" class="fixed-left-image" />
                                    <img src="assets/img/marketing/about-img1.jpg" alt="Plumber" class="main-image" />
                                    <img src="assets/img/marketing/square_circles.png" alt="Dots" class="fixed-square-image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="half-image-section about-image-section">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6 left-side-wrap">
                                <div class="image-container pr-4">
                                    <img src="assets/img/marketing/about-imgBox.png" alt="Illustration" class="fixed-left-image" />
                                    <img src="assets/img/marketing/about-img3.jpg" alt="Plumber" class="main-image" />
                                    <img src="assets/img/marketing/square_circles.png" alt="Dots" class="fixed-square-top" />
                                </div>
                                <div class="mt-5 pr-4">
                                    <p class="text">I have been in the apartment property management and investment business for three decades. Never has there been such a promising technology solution that could really move the needle on operational performance in a way that matches with our core values of serving our customers better.</p>
                                    <div class="about-team">
                                        <div class="about-img">
                                            <img src="assets/img/marketing/about-img3.jpg" alt="user" />
                                        </div>
                                        <div class="about-title">
                                            <h5>Tom</h5>
                                            <p>CEO of PMC of over 25,000 units</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 right-side-wrap">
                                <div class="pl-2">
                                    <div class="badge">Our Mission</div>
                                    <h3 class="mb-3">Create a Valuable, Virtuous Network</h3>
                                    <p class="text mb-5">A system beyond a platform, where users are encouraged to support one another through access, value and cooperation. It's already working, and with each new relationship our mission grows.</p>
                                    <div class="image-container">
                                        <img src="assets/img/marketing/about-house.png" alt="Illustration" width="355" class="fixed-house-image" />
                                        <img src="assets/img/marketing/about-img2.jpg" alt="Plumber" class="main-image" />
                                        <img src="assets/img/marketing/square_circles.png" alt="Dots" class="fixed-square-image" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="about-benefit-section">
                    <section class="half-image-section benifit-section">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-5">
                                    <h5>Committed and dedicated</h5>
                                    <h3>We’re a result driven team</h3>
                                    <p class="text">To create a virtuous cycle that empowers our clients with better retention and NOI, that merges fantastic service groups into the moments and needs they serve best and that wows and appreciates renters because of the amazing consumers they are. </p>
                                </div>
                                <div class="col-md-7">
                                    <div class="image-container">
                                        <img src="assets/img/marketing/about-house2.png" alt="Illustration" class="fixed-house-image" />
                                        <img src="assets/img/marketing/about-img4.jpg" alt="Plumber" class="main-image" />
                                    </div>
                                </div>
                            </div>
                            <div class="row about-thumnails">
                                <div class="col-12 col-lg about-thumb">
                                    <img src="assets/img/marketing/about-image-gall1.jpg"  width="250" height="250"  alt="Illustration" />
                                </div>
                                <div class="col-12 col-lg about-thumb">
                                    <img src="assets/img/marketing/about-image-gall2.jpg" width="250" height="250" alt="Illustration" />
                                </div>
                                
                                <div class="col-12 col-lg about-thumb">
                                    <img src="assets/img/marketing/about-image-gall3.jpg" width="250" height="250" alt="Illustration" />
                                    <img src="assets/img/marketing/square_circles.png" alt="Dots" class="fixed-square-image" />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Newsletter />
                <FooterMain />
            </>
        )
    }
}

export default withApollo(AboutUs)