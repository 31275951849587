import React from "react";
import { withApollo } from "react-apollo";
import { Icon } from 'semantic-ui-react';
import { getSizeText, getBedCount, getBathCount } from '../../containers/PropertySearch/helper'

const PropertyBeds = ({ bedrooms, bathrooms, sqft , price}) => {
    const checkObject = (value) => typeof value === 'object'
    const beds = checkObject(bedrooms) ? getBedCount([bedrooms&&bedrooms[0], bedrooms&&bedrooms[1]], false) : bedrooms;
    const baths = checkObject(bedrooms) ? getBathCount([bathrooms&&bathrooms[0], bathrooms&&bathrooms[1]], false) : bathrooms;
    const size = checkObject(sqft) ? getSizeText([sqft&&sqft[0], sqft&&sqft[1]]) : sqft;
    return (<>
        <Icon fitted siz="mini" circular color='purple' name='bed' title="Bedrooms" />
        <strong> {beds || 0}</strong> 
        <span className="seperator"></span>
        <Icon circular siz="mini" color='purple' name='bath' title="Bathrooms" />
        <strong> {baths || 0}</strong>
        <span className="seperator"></span>
        <Icon circular siz="mini" color='purple' name='expand arrows alternate' title="Square Feet" />
        <strong> {size || 0} sq.ft</strong>
      
    </>
    )
}
export default withApollo(PropertyBeds);
