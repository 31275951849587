import React, { useState, useEffect } from "react";
import { Dropdown, Form, Button, Checkbox } from 'semantic-ui-react';
import { MIN, MAX } from '../../utils';
import DatePicker from "../../components/DatePicker"
import {
    getPriceList, priceText, getBedBath, getValue,
    getTextVal, getBedText, getBathText
} from './helper'


function MoreFilters({ values, onChangeField }) {
    const { totalPrices, totalBeds, totalBaths, totalSizes } = values;
    const [priceKey, setPriceKey] = useState(MIN);
    const [minBedOption, setMinBedOption] = useState([]);
    const [maxBedOption, setMaxBedOption] = useState([]);
    const [minBathOption, setMinBathOption] = useState([]);
    const [maxBathOption, setMaxBathOption] = useState([]);

    useEffect(() => {
        setMinBedOption(getBedBath(null, MIN))
        setMaxBedOption(getBedBath(null, MAX))
        setMinBathOption(getBedBath(null, MIN))
        setMaxBathOption(getBedBath(null, MAX))
    }, [])
    return (
        <>
            <Form.Field>
                <div className="date-container">
                    <DatePicker
                        values={values}
                        onChangeField={onChangeField} />
                </div>
            </Form.Field>
            <Form.Dropdown
                placeholder='Price'
                text={priceText(totalPrices)}
                value={priceText(totalPrices)}
                onChange={(e) => onChangeField(`totalPrices`, null)}
                fluid
                selection
                clearable>
                <Dropdown.Menu
                    scrolling={false}
                    onClick={e => e.stopPropagation()}>
                    <Form.Group widths='equal' className="min-max-input">
                        <Form.Input className='search' fluid placeholder='Min'
                            type="number"
                            value={totalPrices ? getTextVal(totalPrices[0]) : ""}
                            onChange={(e, { value }) => {
                                e.stopPropagation()
                                onChangeField(`totalPrices[0]`, getValue(value))
                            }}
                            onClick={(e) => { e.stopPropagation(); setPriceKey(MIN) }} />
                        <Form.Input fluid placeholder='Max'
                            value={totalPrices ? getTextVal(totalPrices[1]) : ""}
                            type="number"
                            onChange={(e, { value }) => {
                                e.stopPropagation()
                                setPriceKey(MAX)
                                onChangeField(`totalPrices[1]`, getValue(value))
                            }}
                            onClick={(e) => { e.stopPropagation(); setPriceKey(MAX) }} />
                    </Form.Group>
                    <Dropdown.Divider />
                    {getPriceList(totalPrices, priceKey).map((option) =>
                        <Dropdown.Item className={priceKey === MAX && "menu-align-right"}
                            key={option.value} {...option} onClick={(e, { value }) => {
                                let key = priceKey === MIN ? 0 : 1;
                                setPriceKey(priceKey === MIN ? MAX : MIN)
                                onChangeField(`totalPrices[${key}]`, value)
                            }} />)}
                </Dropdown.Menu>
            </Form.Dropdown>
            <Form.Dropdown
                pointing="top"
                className='link item'
                placeholder='Beds'
                text={getBedText(totalBeds)}
                value={getBedText(totalBeds)}
                onChange={() => {
                    onChangeField(`totalBeds`, null);
                    setMinBedOption(getBedBath(null, MIN));
                    setMaxBedOption(getBedBath(null, MAX));
                }}
                fluid
                selection
                clearable>
                <Dropdown.Menu onClick={e => e.stopPropagation()} className="inner-dropdown beds-inner-dropdown">
                    <Form.Group widths='equal' className="min-max-input">
                        <Form.Dropdown placeholder='Min'
                            text={totalBeds && getTextVal(totalBeds[0])}
                            value={totalBeds && getTextVal(totalBeds[0])}
                            onChange={(e, { value }) => {
                                e.preventDefault()
                                onChangeField(`totalBeds[0]`, getValue(value))
                            }}
                            onMouseEnter={() => { totalBeds && setMinBedOption(getBedBath(totalBeds[1], MIN)) }}
                            pointing="top"
                            options={minBedOption} fluid selection simple />
                        <Form.Dropdown placeholder='Max'
                            text={totalBeds && getTextVal(totalBeds[1])}
                            value={totalBeds && getTextVal(totalBeds[1])}
                            onChange={(e, { value }) => {
                                e.preventDefault()
                                onChangeField(`totalBeds[1]`, getValue(value))
                            }}
                            onMouseEnter={() => { totalBeds && setMaxBedOption(getBedBath(totalBeds[0], MAX)) }}
                            pointing="top" simple options={maxBedOption} fluid selection />
                    </Form.Group>
                </Dropdown.Menu>
            </Form.Dropdown>
            <Form.Dropdown
                text={getBathText(totalBaths)}
                value={getBathText(totalBaths)}
                onChange={() => {
                    onChangeField(`totalBaths`, null);
                    setMinBathOption(getBedBath(null, MIN));
                    setMaxBathOption(getBedBath(null, MAX));
                }}
                pointing="top"
                className='link item'
                placeholder='Baths'
                fluid
                selection
                clearable>
                <Dropdown.Menu className="inner-dropdown beds-inner-dropdown">
                    <Form.Group widths='equal' className="min-max-input">
                        <Form.Dropdown placeholder='Min'
                            text={totalBaths && getTextVal(totalBaths[0])}
                            value={totalBaths && getTextVal(totalBaths[0])}
                            onChange={(e, { value }) => {
                                onChangeField(`totalBaths[0]`, getValue(value))
                            }}
                            onMouseEnter={() => { totalBaths && setMinBathOption(getBedBath(totalBaths[1], MIN)) }}
                            pointing="top" simple
                            options={minBathOption} fluid selection />
                        <Form.Dropdown placeholder='Max'
                            text={totalBaths && getTextVal(totalBaths[1])}
                            value={totalBaths && getTextVal(totalBaths[1])}
                            onChange={(e, { value }) => {
                                onChangeField(`totalBaths[1]`, getValue(value))
                            }}
                            onMouseEnter={() => { totalBaths && setMaxBathOption(getBedBath(totalBaths[0], MAX)) }}
                            pointing="top" options={maxBathOption} simple fluid selection />
                    </Form.Group>
                </Dropdown.Menu>
            </Form.Dropdown>
            <Form.Input
                type="number"
                value={totalSizes}
                placeholder='Size (sq.ft)'
                onChange={(e, data) => {
                    onChangeField('totalSizes', data.value)
                }}
            />
            <Form.Dropdown
                placeholder='Price'
                text="Listing Type"
                className="link item listing-type"
                fluid
                selection>
                <Dropdown.Menu className="inner-dropdown">
                    <Form.Group widths='equal'>
                        <Form.Field
                            control={Checkbox}
                            label={{ children: 'Short Term Rental' }}
                        />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field
                            control={Checkbox}
                            label={{ children: 'Long Term Rental' }}
                        />
                    </Form.Group>
                </Dropdown.Menu>
            </Form.Dropdown>
            {/* <Button className="btn-gray btn-sm ml-2" type='submit'>More Filters</Button> */}
            {/* <Button className="btn-gradient btn-sm ml-2" type='submit'>Search</Button> */}
        </ >
    )
}

export default MoreFilters;