import React from "react";
import { withApollo } from "react-apollo";
import * as Yup from 'yup';
import FooterMain from '../../components/Footer/FooterMain'
import Header from "../../components/Header";
import Newsletter from "../../components/Newsletter";
import { contactGQL } from "../../store/person/contact";
import { successToast, errorToast } from "../../components/Toaster"
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
    CONTACT,
  } from "../../utils/constants";
  import { getClient } from '../../store/auth';
import { ToastContainer} from "react-toastify";
import mixpanel from 'mixpanel-browser';

import './marketing.scss'

const contactClient = getClient(CONTACT);

function ContactUs() {
     const resetAll = () => {
         return(
            document.getElementById('both').checked = false,
            document.getElementById('short').checked = false,
            document.getElementById('long').checked = false,
            document.getElementById('single').checked = false,
            document.getElementById('multi').checked = false,
            document.getElementById('condo').checked = false,
            document.getElementById('other').checked = false
         )
     }

    const checkbox = (value) => {
       const long = document.getElementById('long')
       const short = document.getElementById('short')
       const both = document.getElementById('both')
       if (value ==='long' ){return (both.checked = false,short.checked = false,long.checked = true)}
       if (value === 'both' ){return (short.checked = false,long.checked = false,both.checked = true)}
       if (value === 'short'){return (long.checked = false,both.checked = false,short.checked = true)}
    }
    const submitManager = (e) => {
        console.log(e)
        // e.preventDefault();
        const data ={
            multi: document.querySelector('#multi').checked,
            single: document.querySelector('#single').checked,
            condo: document.querySelector('#condo').checked,
            other: document.querySelector('#other').checked,
            long: document.querySelector('#long').checked,
            short: document.querySelector('#short').checked,
            both: document.querySelector('#both').checked,
        }
        const propertyArr = []
        if (data.multi === true){propertyArr.push("Apartment")}
        if (data.single === true){propertyArr.push("House")}
        if (data.condo === true){propertyArr.push("Condo")}
        if (data.other === true){propertyArr.push("Other")}
        const propertyType = propertyArr.length === 0 ? null : propertyArr;
            try {
                mixpanel.track('Marketing Page Action', {
                    'ndbId': "0",
                    'sub': 'Demo Request for Property Manager'
                  });
                contactClient.mutate({
                    mutation: contactGQL,
                    variables: {
                            contact: {
                                occupation: "Property_Manager",
                                email: e.email,
                                notes: e.notes,
                                fullName: e.name,
                                company: e.company,
                                phone: e.phone,
                                propertyType,
                                rentalType: data.long ? "Long_Term":null ||data.short ? "Short_Term" : null||data.both ? "Both"  : null
                            }
                    }
                }).then((res) => {
                    resetAll()
                    successToast ("Thanks! We will be in touch with you shortly.")
                }).catch(error => {
                    resetAll()
                    if(error.toString().includes('TypeError') ||error.toString().includes('read-only')){
                        successToast ("Thanks! We will be in touch with you shortly.")
                    }
                    else {
                        errorToast('Failed to send: ' + error)
                    }
                })
            } catch(e) {
                errorToast('Fail to send: ' + e)
            }

      }

      const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return (
        <>
            <Header />
            <ToastContainer />
            <div class="banner banner-service-provider marketing-banner">
                <div class="container text-left">
                    <div class="row">
                        <div class="col-6">
                            <h2>Get a personalized demo of Leasera’s Powerful, multi-channel resident engagement management platform</h2>
                            <h5>Through intelligent, independent integration we are trying to take a fractured, manually intensive and time consuming industry into the future. Don't worry though, we had fun doing it.</h5>
                        </div>
                    </div>
                </div>
              <div class="points-card-wrap">
                <img src="assets/img/points-icon.svg" alt="points" />
                <div class="points-title">Get 500 Loyalty Points</div>
                <div class="points-subtitle">Lorem / dolor sit amet, con adipiscing elit amet. </div>
                {/* eslint-disable */}
                <a href="#" class="btn-white mt-3">Sign Up Now</a>
                {/* eslint-enable */}
              </div>
            </div>
            <div class="contact-section demo-request">
                <section class="contact-form">
                    <div class="container">
                        <div class="faqs-section">
                            <div class="tab-content mt-0" id="myTabContent">
                                <div class="tab-pane fade show active" id="Managers" role="tabpanel" aria-labelledby="Managers-tab">
                                    <p class="contact-text">We’re experiencing a new era of rental network. Your management platform can no longer just offer good accounting. It now needs to handle flexible leasing opportunities and engage with the outside world more effectively. </p>
                                    <p class="contact-text">If you’re seeking powerful, margin boosting, service rich, consumer wow’ing property management software, please fill out the form and let us know how we can help!</p>
                                    <Formik
                                            initialValues={{ name: '',company: '',email: '',phone: '',notes: '' }}
                                            onSubmit={(values, actions)  => {
                                                submitManager(values)
                                                    actions.resetForm();
                                            }}
                                            validationSchema={Yup.object().shape({
                                                name: Yup.string()
                                                    .required('Required').trim(),
                                                    company: Yup.string()
                                                    .required('Required').trim(),
                                                    email: Yup.string().email().required('Required').trim(),
                                                    phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Required'),
                                                    notes:Yup.string().required('Required').trim(),
                                            })}
                                            render={({ values, setFieldValue, errors, touched, handleReset, handleBlur,handleSubmit }) => (
                                    <Form  class="contact-formField" id="manager" >
                                                <div class="row">
                                        <div class="col-12 col-lg-6">
                                            <div className="form-group">
                                            <label for="name">Full Name</label>
                                                <Field onBlur={handleBlur} type="text" class={`form-control ${errors.name && touched.name&& 'is-invalid'}`} name="name" id="name"
                                                            placeholder="" />
                                                <ErrorMessage className="invalid-feedback" name="name" component="div" />
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-6">
                                            <div class="form-group">
                                                <label for="company">Company</label>
                                                <Field type="text" class={`form-control ${errors.company && touched.company&& 'is-invalid'}`} name="company" id="company"
                                                        placeholder="" />
                                                <ErrorMessage className="invalid-feedback" name="company" component="div" />
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-6">
                                                <div class="form-group">
                                                    <label for="email">Email Address</label>
                                                    <Field type="text" class={`form-control ${errors.email && touched.email && 'is-invalid'}`} name="email" id="email"
                                                        placeholder="" />
                                                <ErrorMessage className="invalid-feedback" name="email" component="div" />
                                                </div>
                                            </div>
                                            <div class="col-12 col-lg-6">
                                                <div class="form-group">
                                                    <label for="phone">Phone Number</label>
                                                    <Field type="text" class={`form-control ${errors.phone && touched.phone && 'is-invalid'}`} name="phone" id="phone"
                                                        placeholder="" />
                                                <ErrorMessage className="invalid-feedback" name="phone" component="div" />
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group checkbox-group">
                                                    <label class="checkbox-label">Property Type (optional)</label>
                                                    <p>What type of property do you own/manage?</p>
                                                    
                                                    <div class="row">
                                                        <div class="col-3">
                                                            <label class="custom-checkbox">Multifamily Apartment(s)
                                                                <input type="checkbox"  name="multi" id="multi" value="multi" />
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                        <div class="col-3">
                                                            <label class="custom-checkbox">Single family home(s)
                                                                <input type="checkbox" name="single" id="single" value="single"  />
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                        <div class="col-2">
                                                            <label class="custom-checkbox">Condo(s)
                                                                <input type="checkbox" name="condo" id="condo" value="condo"  />
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                        <div class="col">
                                                            <label class="custom-checkbox">Other
                                                                <input type="checkbox" name="other" id="other" value="other"  />
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group checkbox-group">
                                                    <label class="checkbox-label">Rental Types (optional)</label>
                                                    <p>Do you/or are you seeking renters to sign a 6+ month lease.. then we consider it a long term rental. Less than 6 months, let's talk about short term management.</p>
                                                    <div class="row">
                                                        <div class="col-3">
                                                            <label class="custom-checkbox">Long Term Rental Property
                                                                <input onClick={() => checkbox('long')} type="checkbox" name="long" id="long" value="long" />
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                        <div class="col-3">
                                                            <label class="custom-checkbox">Short Term Rental Property
                                                                <input onClick={()=> checkbox('short')} type="checkbox" name="short" id="short" value="short"  />
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                        <div class="col">
                                                            <label class="custom-checkbox">Both Long & Short Term
                                                                <input onClick={()=> checkbox('both')} type="checkbox" name="both" id="both" value="both" class="both"  />
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="notes">Notes</label>
                                                    <Field type="textarea" class={`form-control ${errors.notes && touched.notes && 'is-invalid'}`} name="notes" id="notes"
                                                        placeholder="" />
                                                <ErrorMessage className="invalid-feedback" name="notes" component="div" />
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-button">
                                                    <button type="submit" class="btn btn-block btn-color" onClick={handleSubmit}>Submit</button>
                                                </div>
                                            </div>
                                        
                                        </div>
                                    </Form>
                                    )} /> 
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Newsletter />
            <FooterMain />
        </>
    )
}

export default withApollo(ContactUs)