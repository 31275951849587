import * as Yup from 'yup';

const MIN_PASS_LENGTH = 8

const stepOne = {
    firstName: Yup.string().required('First name is required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").trim(),
    lastName: Yup.string().required('Last name is required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").trim(),
    // dateOfBirth: Yup.string().test('Date test',
    //     "You must be atleast 18 years old.",
    //     value => {
    //         return moment().diff(moment(value), 'years') >= 18;
    //     }
    // )
}

const stepTwo = {
    password: Yup.string().required('Password is required').min(MIN_PASS_LENGTH, 'Password is too short - should be 8 chars minimum.'),
    passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Password confirm is required'),
    email: Yup.string().email('Please enter valid email').required('Email is required'),
    phoneMobile: Yup.string().required('Phone number is required').matches(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/, 'Please enter a valid phone number')
    // pin: Yup.string().required('Pin is required').min(MIN_PIN_LENGTH, `Pin should be at least ${MIN_PIN_LENGTH} chars long.`),
    // pinConfirm: Yup.string().oneOf([Yup.ref('pin'), null], 'Pins must match').required('Pin confirm is required'),
}

const stepTwoFinal = {
    password: Yup.string().required('Password is required').min(MIN_PASS_LENGTH, 'Password is too short - should be 8 chars minimum.'),
    // passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Password confirm is required'),
    // pin: Yup.string().required('Pin is required').min(MIN_PIN_LENGTH, `Pin should be at least ${MIN_PIN_LENGTH} chars long.`),
    // pinConfirm: Yup.string().oneOf([Yup.ref('pin'), null], 'Pins must match').required('Pin confirm is required'),
}

const stepThree = {
    email: Yup.string().email('Please enter valid email').required('Email is required'),
    phoneMobile: Yup.string().required('Phone number is required').matches(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/, 'Please enter a valid phone number')
}

const stepFour = {
    state: Yup.string().required('State is required'),
    street: Yup.string().required('Street is required'),
    city: Yup.string().required('City is required'),
    postcode: Yup.string().required('Postcode is required').matches(/^\d{5}$/, 'Please enter a valid postcode')
}


export const SignUpFormRenterSchema = {
    firstStep: Yup.object().shape({
        ...stepOne
    }),
    secondStep: Yup.object().shape({
        ...stepTwo
    }),
    thirdStep: Yup.object().shape({
        ...stepThree
    }),
    fourthStep: Yup.object().shape({
        phone: Yup.string().required('Phone number is required').matches(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/, 'Please enter a valid phone number'),
        confirmCode: Yup.string().required('Confirmation code is required')
    }),
    finalStep: Yup.object().shape({
        ...stepOne, ...stepTwoFinal, ...stepThree
    })
}

export const SignUpFormManagerSchema = {
    // firstStep: Yup.object().shape({
    //   ...stepOne,
    //   ...ssnStep
    // }),
    secondStep: Yup.object().shape({
        ...stepTwo,
        ...stepFour
    }), 
    thirdStep: Yup.object().shape({
      ...stepFour
    }),
    finalStep: Yup.object().shape({
      ...stepOne, ...stepTwoFinal, ...stepThree, ...stepFour
    })
  }
