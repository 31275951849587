import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Image } from 'semantic-ui-react';




class ForgotPassword extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        return(
            <Image/>
        )
    }
}

export default withApollo(ForgotPassword)