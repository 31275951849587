import React, { Component } from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { history } from "./utils";
import { ApolloProvider } from "react-apollo";

import Banners from "./components/Banners";
import { AuthProvider } from "./containers/Auth";
import { mainRoutes } from "./routes";
import { getClient } from "./store/auth";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";
import "semantic-ui-css/semantic.min.css";
import "./theme/index.scss";
import ScrollToTop from "./components/ScrollToTop";
import mixpanel from 'mixpanel-browser';
export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: null,
    };
  }

  componentDidMount() {
    mixpanel.init(`${process.env.REACT_APP_MIXPANEL_STAGING}`); 
    mixpanel.track('Marketing Page Action', {
      'ndbId': "0",
      'sub': 'Initial Render'
    });
    history.listen((location, action) => {
      window.scrollTo(0, 0);
      
    });
  }


  render() {
    return (
      <ApolloProvider client={getClient()}>
        <div className="homepage">
          <BrowserRouter history={history}>
            <AuthProvider authVal={this.state.auth}>
              <Banners />
              <ScrollToTop />
              <Switch>
                {mainRoutes.map((route, i) => {
                  return <Route key={i} {...route} path={`${route.path}`} />;
                })}
                <ToastContainer
                  position="top-center"
                  autoClose={2000}
                  hideProgressBar
                  newestOnTop={false}
                  closeOnClick={false}
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover={false}
                />
              </Switch>
            </AuthProvider>
          </BrowserRouter>
        </div>
      </ApolloProvider>
    );
  }
}
