import React, { useState } from 'react';
import DropdownDate from './DropdownDate';
import moment from 'moment';
import { NavLink } from "react-router-dom";
import { SignUpFormRenterSchema, SignUpFormManagerSchema } from './helper';
import { registerAuthToken, getClient, unregisterAuthToken } from '../../../store/auth';
import { ToastContainer } from 'react-toastify';
import { successToast, errorToast } from "../../../components/Toaster";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { PERSON_FN_URL } from '../../../utils/constants';
import { CreatePerson } from '../../../store/person/createPerson';
import { useAuth } from "../../Auth/index.js";
import mixpanel from 'mixpanel-browser';
function FormView({ activeTab, setCount, isRenterSignUp, history, isRenter }) {
    const { signup, auth } = useAuth();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [dateOfBirth, setDob] = useState(new Date());
    const [password, setPassword] = useState();
    const [passwordConfirm, setPasswordConfirm] = useState();
    const [phoneMobile, setPhoneMobile] = useState();
    const [email, setEmail] = useState();
    const [street, setStreet] = useState();
    const [city, setCity] = useState();
    const [state, setState] = useState();
    const [submitting, setSubmitState] = useState();
    const [postcode, setPostcode] = useState();
    /* eslint-disable */
    const [inviteId, setInviteId] = useState(null);
    /* eslint-enable */
    const next = () => {
        let count = 0;
        count = activeTab + 1;
        // if (isRenter() && activeTab === 1)
        //     count = activeTab + 2;
        // else count = activeTab + 1;
        setCount(null, { value: count });
    }

    const handleSignup = () => {
        if (activeTab !== 2) {
            next()
        } else {
            createUserAccount()
        }
    }

    const createUserAccount = async () => {
        mixpanel.track('Sign Up Action', {
            'ndbId': "0",
            'sub': 'Sign Up Submit Click'
          });
        var years = moment().diff(dateOfBirth, 'years',false);
        if(years>=18){
        setSubmitState(true)
        try {
            await signup(email, password)
            const token = await auth.currentUser.getIdToken()
            registerAuthToken(token);
            const client = getClient(PERSON_FN_URL)
            let createPersonInput = { firstName, lastName, dateOfBirth, email, phoneMobile: phoneMobile.toString(), terms: true }

            if (!isRenter()) {
                if (inviteId) {
                    createPersonInput['organizationId'] = inviteId
                }
                createPersonInput['address'] = {
                    street,
                    city,
                    state,
                    postcode
                }
            }
            client.mutate({
                mutation: CreatePerson,
                variables: {
                    input: {
                        ...createPersonInput,
                        roles: [isRenterSignUp],
                    },
                }
            }).then(res => {
                setSubmitState(false)
                successToast('Account successfully created!');
                history.push('/');
                unregisterAuthToken();
            }).catch(e => {
                setSubmitState(false)
                errorToast('Failed to create account' + e);
                unregisterAuthToken()
            })
        } catch (error) {
            console.log(error)
            setSubmitState(false)
            if (error && error.message) {
                errorToast('Failed to create account' + error);
            } else {
                errorToast('Failed to create account' + error);
            }
        }
    }
    else {
        errorToast('Please make sure that you are age 18 or over');
    }
    }

    const onClickedBack = (e, handleSubmit) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            handleSubmit()
        }
    }

    const setDOB = (date) => {
        setDob(date)
    }

    return (<>
        <ToastContainer />
        {activeTab === 0 && <>
            <Formik
                initialValues={{ firstName, lastName, dateOfBirth }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false)
                    handleSignup()
                }}
                validationSchema={SignUpFormRenterSchema.firstStep}
                render={({ values, isSubmitting, setFieldValue, errors, touched, handleSubmit }) => (
                    <Form textAlign="left" onKeyDown={(e) => onClickedBack(e, handleSubmit)}>
                        <div class="form-group">
                            <label for="firstname">First Name</label>
                            <Field type="text" onkeydown="return /[a-z, ]/i.test(event.key)" className={`form-control ${errors.firstName && touched.firstName && 'is-invalid'}`} id="firstname"
                                placeholder="Please enter your first name..." value={firstName}
                                onChange={({ target: { value } }) => {
                                    setFieldValue('firstName', value);
                                    setFirstName(value)
                                }} />
                            <ErrorMessage className="invalid-feedback" name="firstName" component="div" />
                        </div>
                        <div class="form-group">
                            <label for="lastname">Last Name</label>
                            <Field type="text" className={`form-control ${errors.lastName && touched.lastName && 'is-invalid'}`} id="lastname"
                                placeholder="Please enter your last name..." value={lastName}
                                onChange={({ target: { value } }) => {
                                    setFieldValue('lastName', value);
                                    setLastName(value)
                                }} />
                            <ErrorMessage className="invalid-feedback" name="lastName" component="div" />
                        </div>
                        <DropdownDate setDob={setDOB} dateOfBirth={dateOfBirth} />
                        <div className="text-right">
                            {/* <button className="back-btn" type="button" onClick={() => history.goBack()} >Back</button> */}
                            <button type="submit" class="btn btn-block btn-color next-btn" onClick={handleSubmit}>Next</button>
                        </div>
                    </Form>)} /></>}
        {activeTab === 1 && <>
            <Formik
                initialValues={isRenter() ? { password, passwordConfirm, phoneMobile, email } :
                    { password, passwordConfirm, phoneMobile, email, street, city, state, postcode }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false)
                    handleSignup()
                }}
                validationSchema={isRenter() ? SignUpFormRenterSchema.secondStep : SignUpFormManagerSchema.secondStep}
                render={({ values, isSubmitting, setFieldValue, errors, touched, handleSubmit }) => (
                    <Form textAlign="center" onKeyDown={(e) => onClickedBack(e, handleSubmit)}>
                        <div class="form-row">
                            <div class="form-group">
                                <label for="email">Email</label>
                                <Field type="email" className={`form-control ${errors.email && touched.email && 'is-invalid'}`} id="email"
                                    placeholder="Please enter your email..." value={email}
                                    onChange={({ target: { value } }) => {
                                        setFieldValue('email', value);
                                        setEmail(value)
                                    }} />
                                <ErrorMessage className="invalid-feedback" name="email" component="div" />
                            </div>
                            <div class="form-group">
                                <label for="phoneMobile">Phone Number</label>
                                <Field type="text" className={`form-control ${errors.phoneMobile && touched.phoneMobile && 'is-invalid'}`} id="phoneMobile"
                                    placeholder="Please enter your phone number..." value={phoneMobile}
                                    onChange={({ target: { value } }) => {
                                        setFieldValue('phoneMobile', value);
                                        setPhoneMobile(value)
                                    }} />
                                <ErrorMessage className="invalid-feedback" name="phoneMobile" component="div" />
                            </div>
                        </div> 
                        {!isRenter() && <div class="form-row">
                            <div class="form-group">
                                <label for="street">Street</label>
                                <Field type="street" className={`form-control ${errors.street && touched.street && 'is-invalid'}`} maxLength="14" id="street"
                                    placeholder="Enter your street..." value={street}
                                    onChange={({ target: { value } }) => {
                                        setFieldValue('street', value);
                                        setStreet(value)
                                    }} />
                                <ErrorMessage className="invalid-feedback" name="street" component="div" />
                            </div>
                            <div class="form-group">
                                <label for="city">City</label>
                                <Field type="city" className={`form-control ${errors.city && touched.city && 'is-invalid'}`} id="city"
                                    placeholder="Enter your city..." value={city}
                                    onChange={({ target: { value } }) => {
                                        setFieldValue('city', value);
                                        setCity(value)
                                    }} />
                                <ErrorMessage className="invalid-feedback" name="city" component="div" />
                            </div>
                            <div class="form-group">
                                <label for="state">State</label>
                                <Field type="state" className={`form-control ${errors.state && touched.state && 'is-invalid'}`} id="state"
                                    placeholder="Enter your state..." value={state}
                                    onChange={({ target: { value } }) => {
                                        setFieldValue('state', value);
                                        setState(value)
                                    }} />
                                <ErrorMessage className="invalid-feedback" name="state" component="div" />
                            </div>
                            <div class="form-group">
                                <label for="state">Zip</label>
                                <Field type="postcode" className={`form-control ${errors.postcode && touched.postcode && 'is-invalid'}`} id="postcode"
                                    placeholder="Enter your postcode..." value={postcode}
                                    onChange={({ target: { value } }) => {
                                        setFieldValue('postcode', value);
                                        setPostcode(value)
                                    }} />
                                <ErrorMessage className="invalid-feedback" name="postcode" component="div" />
                            </div>
                        </div>}

                        <div class="form-group">
                            <label for="password">Password</label>
                            <Field type="password" className={`form-control ${errors.password && touched.password && 'is-invalid'}`} id="password"
                                placeholder="Please enter your password..." value={password}
                                onChange={({ target: { value } }) => {
                                    setFieldValue('password', value);
                                    setPassword(value)
                                }} />
                            <ErrorMessage className="invalid-feedback" name="password" component="div" />
                        </div>
                        <div class="form-group">
                            <label for="passwordConfirm">Confirm Password</label>
                            <Field type="password" className={`form-control ${errors.passwordConfirm && touched.passwordConfirm && 'is-invalid'}`} id="passwordConfirm"
                                placeholder="Please enter your password..." value={passwordConfirm}
                                onChange={({ target: { value } }) => {
                                    setFieldValue('passwordConfirm', value);
                                    setPasswordConfirm(value)
                                }} />
                            <ErrorMessage className="invalid-feedback" name="passwordConfirm" component="div" />
                        </div>

                        <div className="text-right">
                            <button primary type="submit" size="big" className="btn btn-block btn-color next-btn" onClick={handleSubmit}>Next</button>
                        </div>
                    </Form>)} /> </>}
        {activeTab === 2 && <>
            <Formik
                initialValues={isRenter() ? { firstName, lastName, dateOfBirth, password, phoneMobile, email } : { firstName, lastName, dateOfBirth, password, phoneMobile, email, street, city, state, postcode }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false)
                    handleSignup()
                }}
                render={({ setFieldValue, handleSubmit }) => (
                    <Form textAlign="center" onKeyDown={(e) => onClickedBack(e, handleSubmit)}>
                        <div class="form-row">
                            <div class="form-group">
                                <label for="firstname">First Name</label>
                                <Field type="text" className={'form-control'} id="firstname"
                                    value={firstName}
                                />
                            </div>
                            <div class="form-group">
                                <label for="lastname">Last Name</label>
                                <Field type="text" className={'form-control'} id="lastname"
                                    value={lastName}
                                />
                            </div>                         
                        </div>
                        <div class="form-group">
                                <label for="dob">Date of birth</label>
                                <Field type="dob" className={'form-control'} id="dob"
                                    value={moment(dateOfBirth).format('YYYY/MM/DD')}
                                />
                            </div>
                        <div class="form-row">
                            <div class="form-group">
                                <label for="email">Email</label>
                                <Field type="email" className={'form-control'} id="email"
                                    value={email}
                                />
                            </div>
                            <div class="form-group">
                                <label for="phoneMobile">Phone Number</label>
                                <Field type="text" className={'form-control'} id="phoneMobile"
                                    value={phoneMobile}
                                />
                            </div>                    
                        </div>
                        
                                <div class="form-group">
                                    <label for="password">Password</label>
                                    <Field type="password" className={'form-control'} id="password"
                                        value={password}
                                    />
                                </div>
                            
                        {!isRenter() && <div class="form-row">
                            <div class="form-group">
                                <label for="street">Street</label>
                                <Field type="street" className={'form-control'} id="street"
                                    value={street}
                                />
                            </div>
                            <div class="form-group">
                                <label for="city">City</label>
                                <Field type="city" className={'form-control'} id="city"
                                    value={city}
                                />
                            </div>
                            <div class="form-group">
                                <label for="state">State</label>
                                <Field type="state" className={'form-control'} id="state"
                                    value={state}
                                />
                            </div>
                            <div class="form-group">
                                <label for="state">Zip</label>
                                <Field type="number" className={'form-control'} id="postcode"
                                    value={postcode}
                                />
                            </div>
                        </div>}
                        <div class="termspolicy-txt">
                            By creating an account, I agree to <NavLink target="_blank" exact to="/Terms" activeClassName="active">Terms & Conditions</NavLink>, <NavLink target="_blank" exact to="/rental-agreement" activeClassName="active">Rental Platform Agreement</NavLink>
                                and <NavLink exact to="/Privacy" target="_blank" activeClassName="active">Privacy Policy</NavLink>
                        </div>
                        <button primary type='submit' size="big" disabled={submitting} className="btn btn-block btn-color next-btn" onClick={handleSubmit}>Create Account</button>
                    </Form>
                )} /> </>}
    </>
    );
}

export default FormView;