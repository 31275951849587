import Cookie from 'js-cookie'
import config from '../../config'
import { TOKEN, EXPIRE_TIME, USER_EVENT, MANAGER, RENTER } from '../../utils/constants'
import { auth } from "../../containers/Auth/Firebase" 
import { getEmitter } from '../utils'

export const unregisterAuthToken = () => {
  Cookie.remove(TOKEN, {
    domain: config.domain,
  })
  Cookie.remove(EXPIRE_TIME, {
    domain: config.domain,
  })
  Cookie.remove(MANAGER, {
    domain: config.domain,
  })
  Cookie.remove(RENTER, {
    domain: config.domain,
  })
}

/**
 * @var ignore: Errors occurring at logout to be ignored.
 *
 * Even though client.resetStore on logout is recommended by the official docs:
 * https://www.apollographql.com/docs/react/recipes/authentication.html#login-logout
 * apollo-client throws the following error without any built-in means to wait
 * for queries in flight to be finished, see
 * https://github.com/apollographql/apollo-client/issues/2919
 *
 * Testing haven't proved it would cause any malfunction (and it only occurs
 * on repeated logouts, the 1st won't cause any error), so just ignore that.
 */
//const ignore = ['Store reset while query was in flight']

export const logoutAction = async (
  client,
  callback
) => {
  auth.signOut()

  unregisterAuthToken()
  client.resetStore()
  const evntEmit = getEmitter()
  evntEmit.emit(USER_EVENT)
  if (callback) callback()
  /* TODO: disabled until CORS issues fixed
  if (callback) {
    const clearOnResetStore = client.onResetStore(() => {
      if (callback) callback() // 2nd check required by flow
      clearOnResetStore()
      return Promise.resolve()
    })
  }
  //; // refetch active queries, now without token
  client.resetStore().catch(err => {
    if (!ignore.reduce((res, msg) => err.message.includes(msg) || res, false)) throw err
  })
  */
}

export default logoutAction